import React from 'react'
import Chart from 'react-apexcharts'
import _ from 'lodash'
import {all_classes} from '../data'

var colorPalette = ['#00D8B6','#008FFB',  '#FEB019', '#FF4560', '#775DD0','#FEB881','#888888','#A98884','#BBE4C3',]

export default class FinanceGraph extends React.Component{
    constructor(){
        super()
    this.state = {
        options:{
            chart: {
                type: 'pie',
                width: '100%',
                height: 700 
            },
            dataLabels: {
              enabled: true,
            },
            plotOptions: {
              pie: {
                customScale: 1.0,
                donut: {
                  size: '100%',
                },
                offsetY: 5,
              },
              stroke: {
                colors: undefined
              }
            },
            colors: colorPalette,
            title: {
                text: 'Fees Statistics Class Based',
                style: {
                fontSize: '18px',
                fontFamily:'Sen'
                }
            },
            labels: ['Nursery 1', 'Nursery 2', 'Kindegarten 1','Kindegarten 2','Basic 1', 'Basic 2', 'Basic 3', 'Basic 4', 'Basic 5','Basic 6','Basic 7','Basic 8', 'Basic 9', 'Basic 10', 'Junior High 2','Junior High 3'],
            legend: {
              position: 'bottom',
              offsetY: 5
            }
        },
        
        }
    }

    getClassDaTa = (stage) => {
      var _students = _.filter(this.props.finance_data,(cls) => {return cls.class_admitted === stage && (cls.term_paid === this.props.mode.current_term || cls.term_paid === this.props.mode.current_semester) && new Date(cls.date_paid).getFullYear() === new Date().getFullYear()})
     
      return _.uniqBy(_students,'student_id').length
    }
    getNumberPaidInClass = () => {
      let new_series = []
      if(this.props.finance_data === null || this.props.finance_data === undefined){
        new_series =  [0,0,0,0,0,0,0,0,0,0,0,0]
      }else{
          var n1 = this.getClassDaTa(all_classes[0].value)
          var n2 = this.getClassDaTa(all_classes[1].value)
          var k1 = this.getClassDaTa(all_classes[2].value)
          var k2 = this.getClassDaTa(all_classes[3].value)
          var p1 = this.getClassDaTa(all_classes[4].value)
          var p2 =this.getClassDaTa(all_classes[5].value)
          var p3 = this.getClassDaTa(all_classes[6].value)
          var p4 = this.getClassDaTa(all_classes[7].value)
          var p5 = this.getClassDaTa(all_classes[8].value)
          var p6 =this.getClassDaTa(all_classes[9].value)
          var p7 = this.getClassDaTa(all_classes[10].value)
          var p8 = this.getClassDaTa(all_classes[11].value)
          var p9 = this.getClassDaTa(all_classes[12].value)
          var p10 =this.getClassDaTa(all_classes[13].value)
          var j2 = this.getClassDaTa(all_classes[14].value)
          var j3 = this.getClassDaTa(all_classes[15].value)

          new_series = [n1,n2,k1,k2,p1,p2,p3,p4,p5,p6,p7, p8, p9, p10,j2,j3]
      }
      return new_series
    }
    render(){
        
       const getNewSeries = this.getNumberPaidInClass()
        return(
            <Chart 
             series={getNewSeries}
             options={this.state.options}
             type='pie'
            />
        )
    }
}