import React, { Suspense, useState, useEffect } from "react";
import { PublicRoute, PrivateRoute } from "./routes";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { auth } from "./services";
import {Prepare} from './data/loading'

const Login = React.lazy(() => import("./access/login"));
const Dashboard = React.lazy(() => import("./components/dashboard"));
const Expenses = React.lazy(() => import("./components/expenses"));
const Payroll = React.lazy(() => import("./components/payroll"));
const Payments = React.lazy(() => import("./components/payments"));
const Reports = React.lazy(() => import("./components/reports"));
const Standards = React.lazy(() => import("./components/standards"));
const SchoolFees = React.lazy(() => import("./components/school-fees"))
const OtherFees = React.lazy(() => import ("./components/fees-list"))

export default function App() {
  const [authenticated, setAuth] = useState(false);

  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        setAuth(true);
      } else {
        setAuth(false);
      }
    });
  }, []);

  return (
    <Router>
      {/* Remember to remove it after checks */}
      <Suspense fallback={<Prepare/>}>
        <Switch>
          <PublicRoute
            path="/login"
            component={Login}
            auth={authenticated}
            exact
          />
          <PrivateRoute
            path="/dashboard"
            component={Dashboard}
            exact
            auth={authenticated}
          />

          <PrivateRoute
            path="/"
            component={Dashboard}
            exact
            auth={authenticated}
          />

          <PrivateRoute
            path="/expenses"
            component={Expenses}
            auth={authenticated}
            exact
          />
          <PrivateRoute
            path="/payroll"
            component={Payroll}
            auth={authenticated}
            exact
          />
          <PrivateRoute
            path="/payments"
            component={Payments}
            auth={authenticated}
            exact
          />

          <PrivateRoute
            path="/school-fees"
            component={SchoolFees}
            auth={authenticated}
            exact
          />

          <PrivateRoute
            path="/additional-fees"
            component={OtherFees}
            auth={authenticated}
            exact
          />

          <PrivateRoute
            path="/records"
            component={Reports}
            auth={authenticated}
            exact
          />
          <PrivateRoute
            path="/standards"
            component={Standards}
            auth={authenticated}
            exact
          />
        </Switch>
      </Suspense>
    </Router>
  );
}
