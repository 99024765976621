import React from 'react' 
import Chart from 'react-apexcharts'
import _ from 'lodash'
var colorPalette = ['#FEB881','#88C888','#A98884','#BBE4C3']

const options = (labels) => {
    return {
    chart: {
        type: 'pie',
        width: '100%',
        height: 700
    },
    dataLabels: {
        enabled: true,
    },
    plotOptions: {
        pie: {
            customScale: 1.0,
            donut: {
                size: '100%',
            },
            offsetY: 5,
        },
        stroke: {
            colors: undefined
        }
    },
    colors: colorPalette,
    title: {
        text: 'Expense Statistics',
        style: {
            fontSize: '18px',
            fontFamily: 'Sen'
        }
    },
    labels,
    legend: {
        position: 'bottom',
        offsetY: 5
    }
}
}
export default function ExpenseChart({data}){
    const getFields = () => {
        let labels = [], series = [] 
        const filtered = _.filter(data, dt => dt.type !== ':Total')
        for(var dt of filtered){
            labels.push(dt.type); 
            series.push(dt.data)
        }

        return {
            labels, 
            series
        }
    }
    return(
        <Chart options={options(getFields().labels)} series={getFields().series} type="pie" /> 
    )
}