var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const { ecover } = require("../images");

export default function generate_expense(data) {
  var rows = [
    [
        { text: "GENERATED AT: " + new Date().toGMTString(), colSpan: 5 },
        {},
        {},
        {},
        {},
        { text: "Total :¢" + data[1] },
      ],
      [
        { text: "DESCRIPTION", bold:true, },
        { text: "AMOUNT", bold:true, },
        { text: "BALANCE", bold:true, },
        { text: "DATE", bold:true, },
        { text: "WEEK", bold:true, },
        { text: "INITIALIZER", bold:true, },
      ]];

  for(var dt of data[0]){
    rows.push([
        dt.description, 
        dt.amount, 
        dt.balance, 
        new Date(dt.date).toLocaleDateString(), 
        dt.week, 
        dt.initializer
    ])
  }
  const docDefinition = {
    pageOrientation: "potrait",
    paperSize: "A4",
    pageMargins: [15, 15, 15, 15],
    content: [
      {
        image: ecover,
        width: 595,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: data[4], // expense type
        absolutePosition: { x: 136, y: 55 },
      },
      {
        text: data[3], // term or semester
        absolutePosition: { x: 342, y: 55 },
      },
      {
        text: data[2], //year
        absolutePosition: { x: 530, y: 55 },
      },
      {
        absolutePosition: { x: 30, y: 100 },
        table: {
          widths: [150, 55, 55, 70, 50, 110],
          body:rows
        },
      },
    ],
  };

  return pdfMake.createPdf(docDefinition).print();
}
