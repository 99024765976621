import React from "react";
import Chart from "react-apexcharts";

const options = () => {
  return {
    chart: {
      type: "line",
      height: 328,
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        top: 3,
        left: 2,
        blur: 2,
        opacity: 1,
      },
    },
    stroke: {
      curve: "smooth",
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        stops: [0, 90, 100],
      },
    },
    title: {
      text: "Expense - Revenue Graph",
      align: "left",
      style: {
        fontSize: "18px",
        fontFamily: "Sen",
      },
      offsetY: 5,
      offsetX: 5,
    },
    markers: {
      size: 6,
      strokeWidth: 0,
      hover: {
        size: 9,
      },
      style: {
        fontSize: "12px",
        fontFamily: "Sen",
      },
    },
    grid: {
      show: true,
      padding: {
        bottom: 0,
      },
    },
    labels: [
      "JAN",
      "FEB",
      "MAR",
      "APR",
      "MAY",
      "JUN",
      "JUL",
      "AUG",
      "SEP",
      "OCT",
      "NOV",
      "DEC",
    ],
    xaxis: {
      style: {
        fontFamily: "Sen",
      },
      tooltip: {
        enabled: true,
        style: {
          fontFamily: "Sen !important",
        },
      },
    },
    legend: {
      style: {
        fontFamily: "Sen",
      },
      position: "bottom",
      horizontalAlign: "left",
      offsetY: 10,
    },
  };
};
export default function ExpenseRevenue({data}) {
  const { expenses, addpayments, fees_data } = data;

  const getMonth = (date, month) => {
    return new Date(date).getMonth() === month - 1;
  };

  const getRevenue = () => {
    let jan = 0,
      feb = 0,
      mar = 0,
      apr = 0,
      may = 0,
      jun = 0,
      jul = 0,
      aug = 0,
      sep = 0,
      oct = 0,
      nov = 0,
      dec = 0;
    for (var pay of addpayments) {
      if (getMonth(pay.date_paid, 1)) {
        jan += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 2)) {
        feb += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 3)) {
        mar += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 4)) {
        apr += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 5)) {
        may += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 6)) {
        jun += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 7)) {
        jul += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 8)) {
        aug += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 9)) {
        sep += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 10)) {
        oct += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 11)) {
        nov += parseFloat(pay.amount_paid);
      }

      if (getMonth(pay.date_paid, 12)) {
        dec += parseFloat(pay.amount_paid);
      }
    }

    return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
  };

  const getExpenses = () => {
    let jan = 0,
    feb = 0,
    mar = 0,
    apr = 0,
    may = 0,
    jun = 0,
    jul = 0,
    aug = 0,
    sep = 0,
    oct = 0,
    nov = 0,
    dec = 0;
  for (var exp of expenses) {
    if (getMonth(exp.date, 1)) {
      jan += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 2)) {
      feb += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 3)) {
      mar += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 4)) {
      apr += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 5)) {
      may += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 6)) {
      jun += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 7)) {
      jul += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 8)) {
      aug += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 9)) {
      sep += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 10)) {
      oct += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 11)) {
      nov += parseFloat(exp.amount);
    }

    if (getMonth(exp.date, 12)) {
      dec += parseFloat(exp.amount);
    }
  }

  return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
  };

  const getSchoolFees = () => {
    let jan = 0,
    feb = 0,
    mar = 0,
    apr = 0,
    may = 0,
    jun = 0,
    jul = 0,
    aug = 0,
    sep = 0,
    oct = 0,
    nov = 0,
    dec = 0;
  for (var fd of fees_data) {
    if (getMonth(fd.date_paid, 1)) {
      jan += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 2)) {
      feb += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 3)) {
      mar += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 4)) {
      apr += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 5)) {
      may += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 6)) {
      jun += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 7)) {
      jul += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 8)) {
      aug += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 9)) {
      sep += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 10)) {
      oct += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 11)) {
      nov += parseFloat(fd.amount_paid);
    }

    if (getMonth(fd.date_paid, 12)) {
      dec += parseFloat(fd.amount_paid);
    }
  }

  return [jan, feb, mar, apr, may, jun, jul, aug, sep, oct, nov, dec]
  }
  const series = [
    {
      name: "Revenue",
      data: getRevenue(),
    },
    {
      name: "Expenses",
      data: getExpenses(),
    },
    {
      name:"School Fees", 
      data:getSchoolFees()
    }
  ];

  return <Chart options={options()} series={series} type="line" />;
}
