import React from "react";
import {
  Table,
  Segment,
  Header,
  Statistic,
  Grid,
  Button,
  Label,
} from "semantic-ui-react";

import generate_history from "../generators/generate-history";
import generate_expense from '../generators/generate-expense'
export const FeesData = ({ cls, data }) => {
  return (
    <Table celled striped unstackable fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">{cls}</Table.HeaderCell>
          <Table.HeaderCell>Amount: ¢ {data[1]}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Paid by</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data[0].length < 1 ? (
          <Table.Row>
            <Table.Cell colSpan={4}>
              <Segment secondary textAlign="center">
                <Header
                  size="large"
                  content="No Data Available"
                  subheader="..."
                  textAlign="center"
                />
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : (
          data[0].map((fd, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{fd.last_name + " " + fd.other_names}</Table.Cell>
              <Table.Cell>{fd.amount_paid}</Table.Cell>
              <Table.Cell>{fd.date_paid}</Table.Cell>
              <Table.Cell>{fd.paid_by}</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="4">
            <Button
              disabled={data[0].length < 1}
              onClick={() => generate_history(cls, data)}
              floated="right"
              color="orange"
              size="mini"
            >
              Print Report
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export const MonthSummary = ({ data }) => {
  return (
    <Grid centered>
      {data.length < 1 ? (
        <Grid.Column computer="16">
          <Segment raised textAlign="center">
            <Header content="No Montly Data" subheader="..." />
          </Segment>
        </Grid.Column>
      ) : (
        data.map((dt, idx) => (
          <Grid.Column key={idx} computer="12" textAlign="center">
            <Segment raised>
              <Statistic
                size="mini"
                label={dt.month}
                value={"¢" + Number(dt.data).toFixed(2)}
              />
            </Segment>
          </Grid.Column>
        ))
      )}
    </Grid>
  );
};

export const ExpenseData = ({ data, expense }) => {
  return (
    <Table celled striped unstackable fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">{expense}</Table.HeaderCell>
          <Table.HeaderCell>Total Spent: ¢{data[1]}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Date</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Balance</Table.HeaderCell>
          <Table.HeaderCell>Initializer</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data[0].length < 1 ? (
          <Table.Row>
            <Table.Cell colSpan="4">
              <Segment secondary textAlign="center">
                <Header
                  size="large"
                  content="No Data Available"
                  subheader="..."
                  textAlign="center"
                />
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : (
          data[0].map((dt, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{dt.date}</Table.Cell>
              <Table.Cell>{dt.amount}</Table.Cell>
              <Table.Cell>{dt.balance}</Table.Cell>
              <Table.Cell>{dt.initializer}</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="4">
            <Button
              disabled={data[0].length < 1}
              onClick={() => generate_expense(data)}
              floated="right"
              color="orange"
              size="mini"
            >
              Print Report
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export const RevenueData = ({ data, revenue, cls }) => {
  return (
    <Table celled striped unstackable fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell colSpan="3">{revenue + "-" + cls}</Table.HeaderCell>
          <Table.HeaderCell>Total Amount: ¢ {data[1]}</Table.HeaderCell>
        </Table.Row>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Amount</Table.HeaderCell>
          <Table.HeaderCell>Balance</Table.HeaderCell>
          <Table.HeaderCell>Date</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data[0].length < 1 ? (
          <Table.Row>
            <Table.Cell colSpan="4">
              <Segment secondary textAlign="center">
                <Header
                  size="large"
                  content="No Data Available"
                  subheader="..."
                  textAlign="center"
                />
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : (
          data[0].map((dt, idx) => (
            <Table.Row key={idx}>
              <Table.Cell>{dt.other_names + " " + dt.last_name}</Table.Cell>
              <Table.Cell>{dt.amount_paid}</Table.Cell>
              <Table.Cell>{dt.balance}</Table.Cell>
              <Table.Cell>{dt.date_paid}</Table.Cell>
            </Table.Row>
          ))
        )}
      </Table.Body>
      <Table.Footer>
        <Table.Row>
          <Table.Cell colSpan="4">
            <Button
              floated="right"
              disabled={data[0].length < 1}
              color="orange"
              size="mini"
              onClick={() => generate_history(cls, data)}
            >
              Print Report
            </Button>
          </Table.Cell>
        </Table.Row>
      </Table.Footer>
    </Table>
  );
};

export const PayrollData = ({ data }) => {


  return (
    <Table celled striped unstackable fixed singleLine>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Name</Table.HeaderCell>
          <Table.HeaderCell>Gross </Table.HeaderCell>
          <Table.HeaderCell>Tax</Table.HeaderCell>
          <Table.HeaderCell>SSNIT</Table.HeaderCell>
          <Table.HeaderCell>Bank</Table.HeaderCell>
          <Table.HeaderCell>Others</Table.HeaderCell>
          <Table.HeaderCell>Net</Table.HeaderCell>
          <Table.HeaderCell>¢ {Number(data[1]).toFixed(1)}</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {data[0].length < 1 ? (
          <Table.Row>
            <Table.Cell colSpan="8">
              <Segment secondary textAlign="center">
                <Header
                  size="large"
                  content="No Data Available"
                  subheader="Payroll Data not Available"
                  textAlign="center"
                />
              </Segment>
            </Table.Cell>
          </Table.Row>
        ) : (
          data[0].map((slp, idx) => (
            <React.Fragment key={idx}>
              <br />
              <Label ribbon size="small" color="orange">
                {slp.month}
              </Label>
              <br />
              <br />
              {slp.data.map((dt, idx) => (
                <React.Fragment key={idx}>
                  <Table.Row>
                    <Table.Cell colSpan={8}>
                      <Label ribbon="right" size="small" color="orange">
                        {dt.category}s
                      </Label>
                    </Table.Cell>
                  </Table.Row>
                  {dt.data.map((info, idx) => (
                    <Table.Row key={idx}>
                      <Table.Cell>
                        {info.last_name + " " + info.first_name}
                      </Table.Cell>
                      <Table.Cell>{info.gross_salary}</Table.Cell>
                      <Table.Cell>{info.tax_charges}</Table.Cell>
                      <Table.Cell>{info.ssnit_charges}</Table.Cell>
                      <Table.Cell>{info.bank_charges}</Table.Cell>
                      <Table.Cell>{info.other_charges}</Table.Cell>
                      <Table.Cell>{info.net_salary}</Table.Cell>
                      <Table.Cell>{info.bonus}</Table.Cell>
                    </Table.Row>
                  ))}
                </React.Fragment>
              ))}
            </React.Fragment>
          ))
        )}
      </Table.Body>
    </Table>
  );
};
