import React, { useState, useEffect } from "react";
import {
  Grid,
  Segment,
  Table,
  Form,
  Input,
  Select,
  TextArea,
  Button,
  Confirm,
  Header,
  Dropdown
} from "semantic-ui-react";
import { weeks, _days } from "../data";
import Feedback from "../data/feedback";
import { db, auth, storage } from "../services";
import _ from "lodash";

export default function Expenses({ sett }) {
  const [expense, setExpense] = useState([]);
  const [day, setDay] = useState();
  const [week, setWeek] = useState();
  const [date, setDate] = useState(new Date().toLocaleDateString());
  const [exp_type, setExpenseType] = useState();
  const [amount, setAmount] = useState();
  const [description, setDescription] = useState();
  const [file, setFile] = useState(null);
  const [filter, setFilter] = useState("All");
  const [_id, setId] = useState();
  const [initializer, setInitializer] = useState();
  const [confirm, setConfirm] = useState(false);
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [msg, setMsg] = useState("");
  const [opn, setOpn] = useState(false);
  const [msgType, setMType] = useState("");
  const [updateMode, setUpdateMode] = useState(false);
  const [upconfirm, setUpdateConfirm] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false)

  useEffect(() => {
    db.collection("expenses").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      setExpense(temp_data);
      temp_data = [];
    });
    db.collection("expense_types").onSnapshot((qss) => {
      let temp_data = [{_id:"All",text:"All", value:"All"}];
      qss.forEach((doc) => {
        temp_data.push({
          _id: doc.data().id,
          text: doc.data().type,
          value: doc.data().type,
        });
      });
      setExpenseTypes(temp_data);
      temp_data = [];
    });
  }, [expense, expenseTypes]);

  const getFileURL = event => {
    const storageChild = storage.child(`'expenses/${sett.current_term}/${exp_type}/${day} - ${initializer}`)
    storageChild.put(event.target.files[0]).then(() => {
      storageChild.getDownloadURL().then(url => {
        setFile(url);
      })
    }).catch((err) => {
        setMsg(err.message); setMType('error'); setOpn(true)
    })
  }
  const uploadExpenseInfo = () => {

    db.collection("expenses")
      .add({
        day,
        week,
        date: new Date(date).toLocaleDateString(),
        exp_type,
        amount: parseFloat(amount),
        description,
        file,
        term: sett.current_term,
        semester: sett.current_semester,
        initializer,
        processed_by:
        auth().currentUser.displayName ?? auth().currentUser.email,
        actual_date: new Date().toDateString(),
        actual_time: new Date().toTimeString(),
      })
      .then(() => {
        setOpn(true);
        setMType("success");
        setMsg("Expense Information Added Successfully");
        setDay();
        setWeek();
        setDate();
        setExpenseType();
        setAmount();
        setDescription();
        setInitializer();
      })
      .catch((err) => {
        setOpn(true);
        setMType("error");
        setMsg(err.message);
      });
  };
  const deleteExpense = (_id) => {
    db.collection("expenses")
      .doc(_id)
      .delete()
      .then(() => {
        setOpn(true);
        setMType("success");
        setMsg("Expense Deletion Successful");
      })
      .catch((err) => {
        setOpn(true);
        setMType("error");
        setMsg(err.message);
      });
  };

  const updateExpense = (_id) => {
    db.collection("expenses")
      .doc(_id)
      .get()
      .then((resp) => {
        const rp = resp.data();
        setDay(rp.day);
        setWeek(rp.week);
        setDate(rp.date);
        setExpenseType(rp.exp_type);
        setAmount(rp.amount);
        setDescription(rp.description);
        setInitializer(rp.initializer);
        setUpdateMode(true);
      });
  };

  const updateExpenseInfo = () => {
    db.collection("expenses")
      .doc(_id)
      .update({
        day,
        week,
        date,
        exp_type,
        amount: parseFloat(amount),
        description,
        initializer,
      })
      .then(() => {
        setOpn(true);
        setMType("success");
        setMsg("Expense Information Update Successful");
      })
      .catch((err) => {
        setOpn(true);
        setMType("error");
        setMsg(err.message);
      });
  };

  const filterExpense = () => {
    const expenses = _.sortBy(expense, ex => new Date(ex.actual_date).getTime())
    return filter === "All"
      ? expenses
      : _.filter(expenses, (ep) => ep.exp_type === filter);
  };
  return (
    <Grid padded>
      <Feedback
        open={opn}
        close={() => setOpn(false)}
        message={msg}
        message_type={msgType}
      />

      <Confirm
        size="mini"
        open={confirm}
        closeOnDimmerClick={false}
        header="Confirm Expense Information"
        content="This action will add expense Information. Continue ?"
        onCancel={() => setConfirm(false)}
        onConfirm={() => {
          setConfirm(false);
          uploadExpenseInfo();
        }}
        cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
        confirmButton={
          <Button size="tiny" content="Yes, Continue" color="green" />
        }
      />
      <Confirm
        size="mini"
        open={upconfirm}
        closeOnDimmerClick={false}
        header="Expense Information Update"
        content="This action will update expense information. Continue ?"
        onCancel={() => setUpdateConfirm(false)}
        onConfirm={() => {
          setUpdateConfirm(false);
          updateExpenseInfo();
        }}
        cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
        confirmButton={
          <Button size="tiny" content="Yes, Continue" color="green" />
        }
      />

      <Confirm
        size="mini"
        open={deleteConfirm}
        closeOnDimmerClick={false}
        header="Expense Information Deletion"
        content="This action will delete expense information. Continue ?"
        onCancel={() => setDeleteConfirm(false)}
        onConfirm={() => {
          setDeleteConfirm(false);
          deleteExpense(_id);
        }}
        cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
        confirmButton={
          <Button size="tiny" content="Yes, Continue" color="green" />
        }
      />

      <Grid.Column computer={4} mobile={16}>
        <Segment raised style={{ minHeight: "100vh" }}>
          <Header content="Expenses Info Addition" />
          <Form>
            {/* 
                day, month, date & time, expense type, file? amount?, initializer, week, description, balance?
                */}

            <Form.Field
              control={Select}
              value={day}
              required
              label="Day"
              options={_days}
              onChange={(e, target) => setDay(target.value)}
            />
            <Form.Field
              control={Select}
              value={week}
              required
              label="Week"
              options={weeks}
              onChange={(e, target) => setWeek(target.value)}
            />
            <Form.Field
              type="date"
              value={date}
              control={Input}
              onChange={(e) => setDate(e.target.value)}
              label="Date"
            />
            <Form.Field
              control={Select}
              value={exp_type}
              required
              options={expenseTypes}
              label="Expense Type"
              onChange={(e, target) => setExpenseType(target.value)}
            />
            <Form.Group>
              <Form.Field
                width={8}
                value={amount}
                required
                control={Input}
                type="number"
                onChange={(e) => setAmount(e.target.value)}
                label="Amount"
              />
            </Form.Group>
            <Form.Field
              label="Description"
              value={description}
              required
              control={TextArea}
              spellCheck="true"
              onChange={(e) => setDescription(e.target.value)}
            />
            <Form.Field
              control={Input}
              value={initializer}
              required
              onChange={(e) => setInitializer(e.target.value)}
              label="Initializer"
            />
            <Input
              icon={{ name: "upload", color: "green" }}
              type="file"
              accept="file/*"
              onChange={(e) => getFileURL(e)}
            />

            <br />
            <br />
            {updateMode === false ? (
              <Button
              disabled={exp_type === "All"}
                type="submit"
                color="orange"
                icon="send"
                circular
                size="tiny"
                onClick={() => setConfirm(true)}
              />
            ) : (
              <Button
                type="submit"
                disabled={exp_type === "All"}
                color="green"
                size="tiny"
                onClick={() => setUpdateConfirm(true)}
              >
                Update Expense
              </Button>
            )}
          </Form>
        </Segment>
      </Grid.Column>
      <Grid.Column computer={12} mobile={16}>
        <Segment raised style={{ minHeight: "50vh" }}>
          <Dropdown
            button
            style={{ float: "right" }}
            className="icon"
            labeled
            value={filter}
            icon="filter"
            options={expenseTypes}
            onChange={(e, target) => setFilter(target.value)}
            text="Filter"
          />
          <Table celled striped padded unstackable>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Day</Table.HeaderCell>
                <Table.HeaderCell>Week </Table.HeaderCell>
                <Table.HeaderCell>Date</Table.HeaderCell>
                <Table.HeaderCell>Expense Type </Table.HeaderCell>
                <Table.HeaderCell>Amount </Table.HeaderCell>
                <Table.HeaderCell>Initializer </Table.HeaderCell>
                <Table.HeaderCell>Description </Table.HeaderCell>
                <Table.HeaderCell>Actions</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {filterExpense().map((exp) => (
                <Table.Row key={exp._id} textAlign="center">
                  <Table.Cell>{exp.day}</Table.Cell>
                  <Table.Cell>{exp.week}</Table.Cell>
                  <Table.Cell>{exp.date}</Table.Cell>
                  <Table.Cell>{exp.exp_type}</Table.Cell>
                  <Table.Cell>{exp.amount}</Table.Cell>
                  <Table.Cell>{exp.balance}</Table.Cell>
                  <Table.Cell>{exp.initializer}</Table.Cell>
                  <Table.Cell>{exp.description}</Table.Cell>
                  <Table.Cell>
                    <Button.Group size="mini">
                      <Button
                        color="red"
                        icon="trash"
                        onClick={() => {
                          setId(exp._id);
                          setDeleteConfirm(true);
                        }}
                        
                      />
                      <Button
                        color="green"
                        icon="sync"
                        onClick={() => {
                          updateExpense(exp._id);

                          setId(exp._id);
                        }}
                      />

                      <Button
                      as="a"
                      href={file}
                      download
                        color="blue"
                        icon="download"
                        disabled={exp.file === null || exp.file === undefined}
                      />
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
