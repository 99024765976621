export const all_classes = [
    {
        value: 'Nursery 1',
        text: 'Nursery 1',
        index: 0

    },
    {
        value: 'Nursery 2',
        text: 'Nursery 2',
        index: 1

    },
    {
        value: 'Kindergarten 1',
        text: 'Kindergarten 1',
        index: 2

    },
    {
        value: 'Kindergarten 2',
        text: 'Kindergarten 2',
        index: 3

    },
    {
        value: 'Basic 1',
        text: 'Basic 1',
        index: 4

    },
    {
        value: 'Basic 2',
        text: 'Basic 2',
        index: 5

    },
    {
        value: 'Basic 3',
        text: 'Basic 3',
        index: 6

    },
    {
        value: 'Basic 4',
        text: 'Basic 4',
        index: 7

    },

    {
        value: 'Basic 5',
        text: 'Basic 5',
        index: 8

    },

    {
        value: 'Basic 6',
        text: 'Basic 6',
        index: 9

    },
    {
        value: 'Basic 7',
        text: 'Basic 7',
        index: 10

    },
    {
        value: 'Basic 8',
        text: 'Basic 8',
        index: 11

    },
    {
        value: 'Basic 9',
        text: 'Basic 9',
        index: 12

    },
    {
        value: 'Basic 10',
        text: 'Basic 10',
        index: 13

    },
    {
        value: 'Junior High 2',
        text: 'Junior High 2',
        index: 14

    },

    {
        value: 'Junior High 3',
        text: 'Junior High 3',
        index: 15

    }
]

export const _days = [
    {
        text: 'Monday',
        value: "Monday",
    },
    {
        text: 'Tuesday',
        value: "Tuesday",
    },
    {
        text: 'Wednesday',
        value: "Wednesday",
    },
    {
        text: 'Thursday',
        value: "Thursday",
    },
    {
        text: 'Friday',
        value: "Friday"
    }

]

export const weeks = [
    {
        text: 'Week 1',
        value: 'Week 1'
    },
    {
        text: 'Week 2',
        value: 'Week 2'
    },
    {
        text: 'Week 3',
        value: 'Week 3'
    },
    {
        text: 'Week 4',
        value: 'Week 4'
    },
    {
        text: 'Week 5',
        value: 'Week 5'
    },
    {
        text: 'Week 6',
        value: 'Week 6'
    },
    {
        text: 'Week 7',
        value: 'Week 7'
    },
    {
        text: 'Week 8',
        value: 'Week 8'
    },
    {
        text: 'Week 9',
        value: 'Week 9'
    },
    {
        text: 'Week 10',
        value: 'Week 10'
    },
    {
        text: 'Week 11',
        value: 'Week 11'
    },
    {
        text: 'Week 12',
        value: 'Week 12'
    },
    {
        text: 'Week 13',
        value: 'Week 13'
    },
    {
        text: 'Week 14',
        value: 'Week 14'
    },
    {
        text: 'Week 15',
        value: 'Week 15'
    },
    {
        text: 'Week 16',
        value: 'Week 16'
    }
]

export const terms = [
    {
        text: "First Term",
        value: "First Term"
    },
    {
        text: "Second Term",
        value: "Second Term"
    },
    {
        text: "Third Term",
        value: "Third Term"
    }]

export const semesters = [
    {
        text: "First Semester",
        value: "First Semester"
    },
    {
        text: "Second Semester",
        value: "Second Semester"
    }
]

export const months = [
    {
        text:"January", 
        value:"January"
    },
    {
        text:"February", 
        value:"February"
    },
    {
        text:"March", 
        value:"March"
    },
    {
        text:"April", 
        value:"April"
    },
    {
        text:"May", 
        value:"May"
    },
    {
        text:"June", 
        value:"June"
    },
    {
        text:"July", 
        value:"July"
    },
    {
        text:"August", 
        value:"August"
    },
    {
        text:"September", 
        value:"September"
    },
    {
        text:"October", 
        value:"October"
    },
    {
        text:"November", 
        value:"November"
    },
    {
        text:"December", 
        value:"December"
    }
]

export const years = [{
    text:"2021",
    value:2021
},
{
    text:"2022",
    value:2022,
    disabled:new Date().getFullYear() < 2022
},
{
    text:"2023",
    value:2023,
    disabled:new Date().getFullYear() < 2023
},
{
    text:"2024",
    value:2024, 
    disabled:new Date().getFullYear() < 2024
},
{
    text:"2025",
    value:2025,
    disabled: new Date().getFullYear() < 2025
}
]