import React from "react";
import {
  Grid,
  Segment,
  List,
  Form,
  Select,
  Image,
  Item,
  Table,
  Confirm,
  Divider,
  Label,
  Button,
  Icon,
  Header,
} from "semantic-ui-react";
import { all_classes, terms, semesters } from "../data";
import SchoolFeesPayment from "../subs/fee_payment";
import { generateStatus } from "../functions";
import generate_receipt from "../generators/generate_receipt";
import generate_status from "../generators/generate-fees-status";
import OtherPayments from "../subs/other_payments";
import _ from "lodash";
import { db } from "../services";
import Feedback from "../data/feedback";
export default class Payments extends React.Component {
  constructor() {
    super();
    this.state = {
      current_class: "Nursery 1",
      payment_type: "School Fees",
      payment_categories: [],
      fees_list: [],
      other_fees: [],
      profile: { last_name: "", other_names: "" },
      current_term: "",
      delete_id: "",
      fees_type: "",
      opn: false,
      message: "",
      message_type: "",
      confirm_delete: false,
      confirm_of: false,
    };
  }

  componentDidMount() {
    db.collection("fee_payment").onSnapshot((qsn) => {
      let temp_data = [];
      qsn.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ fees_list: temp_data }, () => {
        temp_data = [];
      });
    });

    db.collection("other_payments").onSnapshot((qsn) => {
      let temp_data = [];
      qsn.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ other_fees: temp_data }, () => {
        temp_data = [];
      });
    });

    db.collection("payment_categories").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({
          text: doc.data().type,
          value: doc.data().type,
          amount:doc.data().amount,
          _id: doc.id,
        });
      });
      this.setState({ payment_categories: temp_data }, () => (temp_data = []));
    });
  }

  currentClsStudents = () => {
    return _.filter(
      this.props.students,
      (sd) => sd.class_admitted === this.state.current_class
    );
  };

  getClassFee = (_cls) => {
    let tFees = 0.0;

    if (
      _cls === all_classes[0].value ||
      _cls === all_classes[1].value ||
      _cls === all_classes[2].value ||
      _cls === all_classes[3].value
    ) {
      tFees = this.props.sett.pre_school_fees;
    } else if (
      _cls === all_classes[4].value ||
      _cls === all_classes[5].value ||
      _cls === all_classes[6].value
    ) {
      tFees = this.props.sett.lower_primary_fees;
    } else if (
      _cls === all_classes[7].value ||
      _cls === all_classes[8].value ||
      _cls === all_classes[9].value
    ) {
      tFees = this.props.sett.upper_primary_fees;
    } else if (
      _cls === all_classes[10].value ||
      _cls === all_classes[11].value ||
      _cls === all_classes[12].value ||
      _cls === all_classes[13].value ||
      _cls === all_classes[14].value ||
      _cls === all_classes[15].value
    ) {
      tFees = this.props.sett.junior_high_fees;
    }

    return tFees;
  };

  getPaymentStatus = (_id) => {
    const fees = this.getClassFee(this.state.current_class);

    let allPays = _.filter(this.state.fees_list, (std) => {
      return (
        std.student_id === _id &&
        std.class_admitted === this.state.current_class &&
        std.term_paid === this.state.current_term &&
        new Date(std.date_paid).getFullYear() === new Date().getFullYear()
      );
    });

    const amount_paid = _.sumBy(allPays, (sd) => parseFloat(sd.amount_paid));
    if (amount_paid <= 0) {
      return (
        <>
          <Icon name="delete" color="red" /> Not Paid: 0.00
        </>
      );
    } else if (amount_paid >= fees) {
      return (
        <>
          <Icon name="checkmark" color="green" /> Full Payment: {amount_paid}
        </>
      );
    } else {
      return (
        <>
          <Icon name="minus" color="orange" />
          Part Payment: {amount_paid}
        </>
      );
    }
  };

  getPaymentHistory = (_id) => {
    const history = _.filter(this.state.fees_list, (std) => {
      return std.student_id === _id;
    });

    const other = _.filter(
      this.state.other_fees,
      (std) => std.student_id === _id
    );

    const data = [...history, ...other];
    return _.sortBy(data, (hd) => new Date(hd.date_paid).getTime());
  };

  getCurrentTerm = (name) => {
    this.setState({ current_term: name });
  };

  getOtherPaymentsHistory = (cls) => {
    let data = _.filter(
      this.state.other_fees,
      (fd) =>
        fd.term_paid === this.state.current_term &&
        fd.class_admitted === cls &&
        new Date().getFullYear() === new Date(fd.date_paid).getFullYear()
    );
    let grouped = _.groupBy(data, (dt) => dt.fees_type);
    let history = [];
    Object.entries(grouped).forEach(([key, value]) => {
      history.push({ type: key, data: value });
    });
    return history;
  };

  getClassPayment = (cls) => {
    const data = _.filter(
      this.state.fees_list,
      (cd) =>
        cd.class_admitted === cls &&
        cd.term_paid === this.state.current_term &&
        new Date().getFullYear() === new Date(cd.date_paid).getFullYear()
    );
    const fees = this.getClassFee(cls);
    const sortedData = _.sortBy(data, (cc) => new Date(cc.date_paid).getTime());
    const students = this.currentClsStudents() 
    return generateStatus({
      data: sortedData,
      amount: fees,
      division: students,
    });
  };

  deleteOtherPayments = (_id) => {
    db.collection("other_payments")
      .doc(_id)
      .delete()
      .then(() => {
        this.setState({
          opn: true,
          message: "Fee Deletion Successful",
          message_type: "success",
        });
      })
      .catch((err) => {
        this.setState({
          opn: true,
          message: err.message,
          message_type: "error",
        });
      });
  };

  deleteSchoolFees = (_id) => {
    db.collection("fee_payment")
      .doc(_id)
      .delete()
      .then(() => {
        this.setState({
          opn: true,
          message: "Fee Deletion Successful",
          message_type: "success",
        });
      })
      .catch((err) => {
        this.setState({
          opn: true,
          message: err.message,
          message_type: "error",
        });
      });
  };

  getType = (cls) => {
    return (
      cls.charAt(0) === "J" ||
      cls === "Basic 7" ||
      cls === "Basic 8" ||
      cls === "Basic 9" ||
      cls === "Basic 10"
    );
  };

  render() {
    const { profile } = this.state;
    return (
      <Grid>
        {/* School Fees Deletion Confirm */}
        <Confirm
          size="tiny"
          open={this.state.confirm_delete}
          closeOnDimmerClick={false}
          header="Confirm School Fees Deletion!"
          content="This action will delete fees data from Database, Continue ?"
          onCancel={() => this.setState({ confirm_delete: false })}
          onConfirm={() => {
            this.setState({ confirm_delete: false }, () =>
              this.deleteSchoolFees(this.state.delete_id)
            );
          }}
          cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
          confirmButton={
            <Button size="tiny" content="Yes, Continue" color="green" />
          }
        />

        {/* Other Deletion Confirm */}
        <Confirm
          size="tiny"
          open={this.state.confirm_of}
          closeOnDimmerClick={false}
          header="Confirm Other Fees Deletion!"
          content="This action will delete fees data from Database, Continue ?"
          onCancel={() => this.setState({ confirm_of: false })}
          onConfirm={() => {
            this.setState({ confirm_of: false }, () =>
              this.deleteOtherPayments(this.state.delete_id)
            );
          }}
          cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
          confirmButton={
            <Button size="tiny" content="Yes, Continue" color="green" />
          }
        />

        <Grid.Column computer={4} mobile={16}>
          <Segment raised style={{ minHeight: "70vh", overflowY: "auto" }}>
            <Form>
              <Form.Field
                label="Payment Type"
                value={this.state.payment_type}
                options={[
                  {
                    text: "School Fees",
                    value: "School Fees",
                  },
                  {
                    text: "Other Payments",
                    value: "Other Payments",
                  },
                ]}
                control={Select}
                onChange={(e, target) =>
                  this.setState({ payment_type: target.value })
                }
              />
              <Form.Field
                label="Select Class"
                value={this.state.current_class}
                control={Select}
                options={all_classes}
                onChange={(e, target) =>
                  this.setState({ current_class: target.value })
                }
              />
              <Form.Field
                control={Select}
                value={this.state.current_term}
                label="Term / Semester"
                disabled={!this.state.current_class}
                placeholder="Select Term"
                onChange={(e, target) =>
                  this.setState({ current_term: target.value })
                }
                options={
                  this.getType(this.state.current_class) ? semesters : terms
                }
              />
            </Form>
            <List relaxed divided>
              {this.currentClsStudents().map((std, idx) => (
                <List.Item
                  key={idx}
                  className={
                    this.state.profile.student_id === std.student_id
                      ? "active-target"
                      : "inactive"
                  }
                >
                  <Image avatar src={std.profile_image} />
                  <List.Content onClick={() => this.setState({ profile: std })}>
                    <List.Header
                      style={{ cursor: "pointer" }}
                      as="h5"
                      content={std.last_name + " " + std.other_names}
                    />
                    <List.Description>
                      {this.state.payment_type === "School Fees"
                        ? this.getPaymentStatus(std.student_id)
                        : ""}
                    </List.Description>
                  </List.Content>
                </List.Item>
              ))}
            </List>
          </Segment>
        </Grid.Column>
        <Grid.Column computer={5} mobile={16}>
          {/* School Fees */}
          {this.state.payment_type === "School Fees" ? (
            <SchoolFeesPayment
              term={this.state.current_term}
              class_fee={this.getClassFee(this.state.current_class)}
              feesData={this.props.fees_data}
              current_student={this.state.profile}
            />
          ) : (
            <OtherPayments
              categories={this.state.payment_categories}
              term={this.state.current_term}
              pInfo={this.state.profile}
              data={this.getOtherPaymentsHistory(this.state.current_class)}
            />
          )}
        </Grid.Column>
        <Grid.Column computer={7} mobile={16}>
          {/* Payment History Student & Class*/}
          {profile.class_admitted === undefined ? null : (
            <Segment raised>
              <Item.Group>
                <Item>
                  <Item.Image size="tiny" src={profile.profile_image} />
                  <Item.Content>
                    <Item.Header>
                      {profile.last_name + " " + profile.other_names}
                    </Item.Header>
                    <Item.Meta>
                      <span>{profile.class_admitted}</span>
                    </Item.Meta>
                    <Item.Description>
                      <Table celled unstackable fixed singleLine>
                        <Table.Header>
                          <Table.Row>
                            <Table.HeaderCell colSpan="5">
                              All Payments History{" "}
                            </Table.HeaderCell>
                          </Table.Row>
                          <Table.Row textAlign="center">
                            <Table.HeaderCell>Date</Table.HeaderCell>
                            <Table.HeaderCell>Amount</Table.HeaderCell>
                            <Table.HeaderCell>Term</Table.HeaderCell>
                            <Table.HeaderCell>Type</Table.HeaderCell>
                            <Table.HeaderCell />
                          </Table.Row>
                        </Table.Header>
                        <Table.Body>
                          {this.getPaymentHistory(profile.student_id).map(
                            (fd, idx) => (
                              <Table.Row key={idx} textAlign="center">
                                <Table.Cell>{fd.date_paid}</Table.Cell>
                                <Table.Cell>{fd.amount_paid}</Table.Cell>
                                <Table.Cell>{fd.term_paid}</Table.Cell>
                                <Table.Cell>
                                  {fd.fees_type ?? "School Fees"}{" "}
                                </Table.Cell>
                                <Table.Cell>
                                  <Button
                                    icon="trash"
                                    disabled={
                                      new Date(
                                        fd.date_paid
                                      ).toLocaleDateString() !==
                                      new Date().toLocaleDateString()
                                    }
                                    onClick={() => {
                                      fd.fees_type === undefined
                                        ? this.setState({
                                            delete_id: fd._id,
                                            confirm_delete: true,
                                          })
                                        : this.setState({
                                            delete_id: fd._id,
                                            confirm_of: true,
                                          });
                                    }}
                                    title="Delete"
                                    circular
                                    size="mini"
                                    color="red"
                                  />
                                  <Button
                                    icon="print"
                                    onClick={() => generate_receipt(fd)}
                                    circular
                                    size="mini"
                                    color="green"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            )
                          )}
                        </Table.Body>
                      </Table>
                    </Item.Description>
                  </Item.Content>
                </Item>
              </Item.Group>
            </Segment>
          )}
          <Divider horizontal />
          <Segment raised>
            {this.state.payment_type === "School Fees" ? (
              <Table celled striped unstackable fixed singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5">
                      <Header
                        content={this.state.current_class}
                        subheader="Payment History"
                      />
                    </Table.HeaderCell>
                  </Table.Row>
                  <Table.Row textAlign="center">
                    <Table.HeaderCell>Profile</Table.HeaderCell>
                    <Table.HeaderCell>Name</Table.HeaderCell>
                    <Table.HeaderCell>Amount </Table.HeaderCell>
                    <Table.HeaderCell>Balance</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.getClassPayment(this.state.current_class).fullPaid.map(
                    (pd, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell textAlign="center">
                          <Image avatar src={pd.photo} />
                        </Table.Cell>
                        <Table.Cell>{pd.name}</Table.Cell>
                        <Table.Cell>{pd.amount_paid}</Table.Cell>
                        <Table.Cell>{pd.balance}</Table.Cell>
                        <Table.Cell>
                          <Label ribbon="right" color="green" size="mini">
                            {pd.status}
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}

                  {this.getClassPayment(this.state.current_class).partPaid.map(
                    (pd, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell>
                          <Image avatar src={pd.photo} />
                        </Table.Cell>
                        <Table.Cell>{pd.name}</Table.Cell>
                        <Table.Cell>{pd.amount_paid}</Table.Cell>
                        <Table.Cell>{pd.balance}</Table.Cell>
                        <Table.Cell>
                          <Label ribbon="right" color="teal" size="mini">
                            {pd.status}
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}

                  {this.getClassPayment(this.state.current_class).noPaid.map(
                    (pd, idx) => (
                      <Table.Row key={idx}>
                        <Table.Cell>
                          <Image avatar src={pd.photo} />
                        </Table.Cell>
                        <Table.Cell>{pd.name}</Table.Cell>
                        <Table.Cell>{pd.amount_paid}</Table.Cell>
                        <Table.Cell>{pd.balance}</Table.Cell>
                        <Table.Cell>
                          <Label ribbon="right" color="red" size="mini">
                            {pd.status}
                          </Label>
                        </Table.Cell>
                      </Table.Row>
                    )
                  )}
                </Table.Body>
                <Table.Footer>
                  <Table.Row>
                    <Table.HeaderCell colSpan="5">
                      <Button
                        floated="right"
                        disabled={
                          this.getClassPayment(this.state.current_class)
                            .length < 1
                        }
                        onClick={() =>
                          generate_status(this.state.current_class, [
                            ...this.getClassPayment(this.state.current_class)
                              .fullPaid,
                            ...this.getClassPayment(this.state.current_class)
                              .partPaid,
                            ...this.getClassPayment(this.state.current_class)
                              .noPaid,
                          ])
                        }
                        size="mini"
                        color="green"
                      >
                        Generate Status Sheet
                      </Button>
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Footer>
              </Table>
            ) : (
              <Table celled striped unstackable fixed singleLine>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Name </Table.HeaderCell>
                    <Table.HeaderCell>Amount </Table.HeaderCell>
                    <Table.HeaderCell>Type</Table.HeaderCell>
                    <Table.HeaderCell>Date</Table.HeaderCell>
                    <Table.HeaderCell>Received By </Table.HeaderCell>
                    <Table.HeaderCell>Actions</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {this.getOtherPaymentsHistory(this.state.current_class).map(
                    (ph, idx) => (
                      <React.Fragment key={idx}>
                        <Label ribbon color="orange">
                          {ph.type}
                        </Label>
                        {ph.data.map((dt) => (
                          <Table.Row key={dt.receipt_id}>
                            <Table.Cell>
                              {dt.other_names + " " + dt.last_name}
                            </Table.Cell>
                            <Table.Cell>{dt.amount_paid} </Table.Cell>
                            <Table.Cell>{dt.fees_type}</Table.Cell>
                            <Table.Cell>{dt.date_paid} </Table.Cell>
                            <Table.Cell>{dt.received_by}</Table.Cell>
                            <Table.Cell>
                              <Button.Group size="mini">
                                <Button
                                  onClick={() =>
                                    this.setState({
                                      delete_id: dt._id,
                                      confirm_of: true,
                                    })
                                  }
                                  icon="trash"
                                  color="red"
                                  disabled={
                                    new Date(dt.date_paid).toDateString() ===
                                    new Date().toDateString()
                                  }
                                />
                                <Button
                                  icon="print"
                                  color="green"
                                  onClick={() => generate_receipt(dt)}
                                />
                              </Button.Group>
                            </Table.Cell>
                          </Table.Row>
                        ))}
                      </React.Fragment>
                    )
                  )}
                </Table.Body>
              </Table>
            )}
          </Segment>
        </Grid.Column>
        <Feedback
          open={this.state.opn}
          close={() => this.setState({ opn: false })}
          message={this.state.message}
          message_type={this.state.message_type}
        />
      </Grid>
    );
  }
}
