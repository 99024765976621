import React from "react";
import { Placeholder, Segment, Loader, Grid } from "semantic-ui-react";

export const Loading = () => {
  return (
    <Placeholder fluid>
      <Placeholder.Header image>
        <Placeholder.Line />
        <Placeholder.Line />
      </Placeholder.Header>
    </Placeholder>
  );
};

export const Prepare = () => {
  return (
    <Grid verticalAlign="middle" centered className="load">
      <Grid.Column computer={4} mobile={10}>
    <Segment secondary className="load-box">
      <Loader indeterminate active size="large">
        Loading
      </Loader>
    </Segment>
    </Grid.Column>
    </Grid>
  );
};
