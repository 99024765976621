import  firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/storage' 
/*
 const firebaseConfig = {
    apiKey: "AIzaSyATT25P68KE9bmDlg3nZicgYiwCGHEKNfA",
    authDomain: "test-66c8d.firebaseapp.com",
    databaseURL: "https://test-66c8d.firebaseio.com",
    projectId: "test-66c8d",
    storageBucket: "test-66c8d.appspot.com",
    messagingSenderId: "531127962891",
    appId: "1:531127962891:web:802b338bd2f3c9f7d3660e",
    measurementId: "G-Z8G4JEM3H3"
  }
   */




 const firebaseConfig = {
    apiKey: "AIzaSyC8WTBZmibFhIaSAtnHYTvMZt0w3Oc5YfE",
    authDomain: "wris-gh.firebaseapp.com",
    projectId: "wris-gh",
    storageBucket: "wris-gh.appspot.com",
    messagingSenderId: "848188025331",
    appId: "1:848188025331:web:828a97a9973084aa5f4a86",
    measurementId: "G-F9WK9GMJMQ"
  }; 
 


firebase.initializeApp(firebaseConfig)

firebase.firestore().settings({
  cacheSizeBytes: firebase.firestore.CACHE_SIZE_UNLIMITED
});

firebase.firestore().enablePersistence()

firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)

export const auth = firebase.auth

export const db  = firebase.firestore()

export const storage = firebase.storage().ref()