import React from "react";
import { Grid, Segment, Statistic, Header } from "semantic-ui-react";
import FinanceStatus from "../subs/stats_table";
import { Loading } from "../data/loading";
import { ExpenseRevenue, ExpenseChart, RevenueChart } from "../graphs";
import _ from "lodash";
export default class Dashboard extends React.Component {
  constructor() {
    super();
    this.state = {};
  }
  getExpenseInformation = () => {
    const { current_term, current_semester } = this.props.sett;
    const all_expenses = _.filter(
      this.props.expenses,
      (ex) =>
        (ex.term === current_term || ex.semester === current_semester) &&
        new Date(ex.actual_date).getFullYear() === new Date().getFullYear()
    );
    const grouped = _.groupBy(all_expenses, (ex) => ex.exp_type);
    const groupToArray = [];
    Object.entries(grouped).forEach(([key, value]) => {
      groupToArray.push({
        type: key,
        data: _.sumBy(value, (sd) => parseFloat(sd.amount)),
      });
    });

    const totalExpense = {
      type: ":Total",
      data: _.sumBy(groupToArray, (gd) => parseFloat(gd.data)),
    };
    const data = [...groupToArray, totalExpense];
    return _.sortBy(data, (gd) => gd.type);
  };

  getFees = () => {
    const { current_term, current_semester } = this.props.sett;

    const termFees = _.filter(
      this.props.fees_data,
      (fd) =>
        fd.term_paid === current_term &&
        new Date(fd.date_paid).getFullYear() === new Date().getFullYear()
    );
    const semesterFees = _.filter(
      this.props.fees_data,
      (fd) =>
        fd.term_paid === current_semester &&
        new Date(fd.date_paid).getFullYear() === new Date().getFullYear()
    );

    return [
      {
        type: "Primary",
        data: _.sumBy(termFees, (td) => parseFloat(td.amount_paid)),
      },
      {
        type: "JHS",
        data: _.sumBy(semesterFees, (td) => parseFloat(td.amount_paid)),
      },
    ];
  };

  getOtherRevenue = () => {
    const { current_term, current_semester } = this.props.sett;
    const allrevs = _.filter(
      this.props.addpayments,
      (ad) =>
        (ad.term_paid === current_term || ad.term_paid === current_semester) &&
        new Date(ad.date_paid).getFullYear() === new Date().getFullYear()
    );
    const grouped = _.groupBy(allrevs, (ad) => ad.fees_type);
    const groupToArray = [];
    Object.entries(grouped).forEach(([key, value]) => {
      groupToArray.push({
        type: key,
        data: _.sumBy(value, (vd) => parseFloat(vd.amount_paid)),
      });
    });

    const totalIncome = {
      type: ":Total",
      data: _.sumBy([...groupToArray, ...this.getFees()], (fd) =>
        parseFloat(fd.data)
      ),
    };
    const data = [...groupToArray, ...this.getFees(), totalIncome];
    return _.sortBy(data, (dt) => dt.type);
  };

  getBalance = () => {
    const revenues = _.find(this.getOtherRevenue(), rv => rv.type === ':Total') 
    const expenses = _.find(this.getExpenseInformation(),  ep => ep.type === ':Total')

    if(revenues === undefined || expenses === undefined){
      return 0.00
    }else{
      return parseInt(revenues.data) - parseInt(expenses.data)
    }
  }

  render() {
    const { expenses, addpayments, fees_data } = this.props;
    return (
      <React.Fragment>
        <Grid centered relaxed>
          <Grid.Column computer={7} mobile={16}>
            <Segment raised secondary>
              <Header content="Expenses" as="h3" />
              <Grid columns={4} className="bar-low" padded={"horizontally"}>
                {this.getExpenseInformation().length < 1 ? (
                  <Grid.Column computer={16}>
                    <Loading />
                  </Grid.Column>
                ) : (
                  this.getExpenseInformation().map((info, idx) => (
                    <Grid.Column key={idx} computer={4} mobile={8}>
                      <Segment raised textAlign="center">
                        <Statistic
                          size="mini"
                          label={info.type}
                          value={"¢" + Number(info.data).toFixed(1)}
                        />
                      </Segment>
                    </Grid.Column>
                  ))
                )}
              </Grid>
            </Segment>
          </Grid.Column>

          <Grid.Column computer={7} mobile={16}>
            <Segment raised secondary>
              <Header content="Revenue" as="h3" />
              <Grid columns={4} className="bar-low" padded={"horizontally"}>
                {this.getOtherRevenue().length < 1 ? (
                  <Grid.Column computer={16}>
                    <Loading />
                  </Grid.Column>
                ) : (
                  this.getOtherRevenue().map((info, idx) => (
                    <Grid.Column key={idx} computer={4} mobile={8}>
                      <Segment raised textAlign="center">
                        <Statistic
                          size="mini"
                          label={info.type}
                          style={{ whiteSpace: "no-wrap" }}
                          value={"¢" + Number(info.data).toFixed(1)}
                        />
                      </Segment>
                    </Grid.Column>
                  ))
                )}
              </Grid>
            </Segment>
          </Grid.Column>
          <Grid.Column computer={2} mobile={16}>

            <Segment raised secondary textAlign="center">
              <Header content="Balance" as="h3" />
              <Segment raised textAlign="center">
              <Statistic
                size="mini"
                label={"GHS"}
                style={{ whiteSpace: "no-wrap" }}
                value={"¢" + this.getBalance()}
              />
              </Segment>
            </Segment>
          </Grid.Column>
        </Grid>

        <Grid padded>
          <Grid.Column computer={11} mobile={16}>
            <Segment raised>
              <ExpenseRevenue
                data={{
                  expenses,
                  addpayments,
                  fees_data,
                }}
              />
            </Segment>
          </Grid.Column>
          <Grid.Column computer={5} mobile={16}>
            <Grid>
              <Grid.Column computer={16}>
                <Segment raised style={{ minHeight: "250px" }}>
                  <ExpenseChart data={this.getExpenseInformation()} />
                </Segment>
              </Grid.Column>

              <Grid.Column computer={16}>
                <Segment raised style={{ minHeight: "250px" }}>
                  <RevenueChart data={this.getOtherRevenue()} />
                </Segment>
              </Grid.Column>
            </Grid>
          </Grid.Column>
          <Grid.Column computer={16}>
            <Segment raised>
              <FinanceStatus
                student_data={this.props.students}
                finance_data={this.props.fees_data}
                mode={this.props.sett}
              />
            </Segment>
          </Grid.Column>
        </Grid>
      </React.Fragment>
    );
  }
}
