var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { pcover } = require("../images");

export default function generate_payslip(info) {

  const name = info.data.first_name + "  " + info.data.last_name;

  const getTotalPercentage = () => {
    const std = info.standard;
    return parseFloat(std.additionals + std.bank_charges + std.ssnit + std.tax);
  };

  const getTotalCharges = () => {
    const dt = info.data;
    return parseFloat(
      dt.bank_charges + dt.ssnit_charges + dt.tax_charges + dt.other_charges
    );
  };
  const docDefinition = {
    pageOrientation: "potrait",
    paperSize: "A5",
    pageMargins: [15, 15, 15, 15],
    content: [
      {
        image: pcover,
        width: 595,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: name.toUpperCase(), //name of Employee
        style: "details",
        absolutePosition: { x: 210, y: 132 },
      },
      {
        text: info.data.staff_id, //staff ID
        style: "details",
        absolutePosition: { x: 155, y: 156 },
      },
      {
        text: info.month.toUpperCase(), //MONTH
        style: "details",
        absolutePosition: { x: 145, y: 180 },
      },
      {
        text: info.phone, //Phone
        style: "details",
        absolutePosition: { x: 365, y: 180 },
      },
      {
        text: new Date(info.date).toGMTString().toUpperCase(), //daTE
        style: "details",
        absolutePosition: { x: 200, y: 204 },
      },
      {
        text: info.data.employee_category, //category
        style: "details",
        absolutePosition: { x: 413, y: 156 },
      },
      {
        absolutePosition: { x: 80, y: 250 },
        table: {
          widths: [220, 100, 100],
          body: [
            [{ text: "EARNINGS", colSpan: 3, style: "head" }, {}, {}],
            [
              { text: "Gross Salary", colSpan: 2 },
              {},
              { text: "GHS " + info.data.gross_salary },
            ],
            [{ text: "Bonus", colSpan: 2 }, {}, { text: "GHS " + info.data.bonus }],
            [{ text: "DEDUCTIONS", colSpan: 3, style: "head" }, {}, {}],
            [
              { text: "Field", style: "sections" },
              { text: "Percentage", style: "sections" },
              { text: "Charge", style: "sections" },
            ],
            [
              { text: "SSNIT" },
              { text: info.standard.ssnit + "%" },
              { text: "GHS " + info.data.ssnit_charges },
            ],
            [
              { text: "Bank Charges" },
              { text: info.standard.bank_charges + "%" },
              { text: "GHS " + info.data.bank_charges },
            ],
            [
              { text: "Tax" },
              { text: info.standard.tax + "%" },
              { text: "GHS " + info.data.tax_charges },
            ],
            [
              { text: "Others" },
              { text: info.standard.additionals + "%" },
              { text: "GHS " + info.data.other_charges },
            ],
            [
              { text: "", colSpan: 1 },
              { text: "Total: " + getTotalPercentage() + " %" },
              { text: "Total: GHS " + getTotalCharges() },
            ],
            [
              { text: "Internal Deductions", colSpan:2 },
              {},
              { text: "GHS " + info.data.deductions ?? 0.00 },
            ],
            [
              { text: "", colSpan: 2 },
              { text: "" },
              { text: "Net: GHS " + (parseFloat(info.data.net_salary) + parseFloat(info.data.bonus)), bold: true },
            ],
          ],
        },
      },
    ],
    styles: {
      details: {
        color: "#fff",
        fontSize: 14,
        bold: true,
      },
      head: {
        fontSize: 14,
        bold: true,
      },
      sections: {
        uppercase: true,
        bold: true,
        fontSize: 12,
      },
    },
  };

  return pdfMake.createPdf(docDefinition).print();
}
