import React from 'react'
import MUIDataTable from 'mui-datatables' 

export default function FeesList({addpayments}){
        const getFields = () => {
            let data = []
            for (var adp of addpayments){
                data.push({
                    receipt_id: adp.receipt_id,
                    student_name: adp.last_name + " " + adp.other_names,
                    class_admitted: adp.class_admitted, 
                    amount_paid:adp.amount_paid, 
                    term:adp.term,
                    balance:adp.balance, 
                    fees_type:adp.fees_type,
                    date_paid: new Date(adp.date_paid).toDateString(),
                    paid_by:adp.paid_by, 
                    received_by: adp.received_by 
                })
            }
            return data
        }
        const fees_columns = [
            {
                name:"receipt_id", 
                label:"Receipt ID",
                options:{
                    filter:false,
                    sort:false
                }
            },
            {
                name:"fees_type", 
                label:"Fees type",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"student_name", 
                label:"Student Name",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"class_admitted", 
                label:"Class Admitted",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"amount_paid", 
                label:"Amount",
                options:{
                    filter:true,
                    sort:true
                }
            },
            
            {
                name:"balance",
                label:"Balance"
            },
            {
                name:"term", 
                label:"Term",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_paid", 
                label:"Date Paid",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"paid_by", 
                label:"Paid By",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"received_by",
                label:"Paid To"
            }

        ]

        return(
            <MUIDataTable 
            title={"Additional Payments"}
            columns={fees_columns}
            data={getFields()}
            options={{
                selectableRows:'none',
                responsive:'standard'
            }}
            />
        )
    }  
