import React, { useEffect, useState } from "react";
import {
  Grid,
  Segment,
  Tab,
  List,
  Header,
  Form,
  Select,
  Button,
} from "semantic-ui-react";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { all_classes, years } from "../data";
import {
  FeesData,
  MonthSummary,
  ExpenseData,
  RevenueData,
  PayrollData,
} from "../data/report-data";

import { db } from "../services";
import _ from "lodash";

const ReportModal = React.lazy(() => import("../data/report-modal"));

export default function Report({ fees_data, expenses, addpayments, payroll }) {
  const [year, setYear] = useState(new Date().getFullYear());
  const [expenseTypes, setExpenseTypes] = useState([]);
  const [expense, setExpense] = useState("");
  const [revenue, setRevenue] = useState("");
  const [paymentCategories, setPaymentCategories] = useState([]);
  const [category, setCategory] = useState("School Fees");
  const [cls, setCls] = useState("Nursery 1");
  const [term, setTerm] = useState("First Term");
  const [open, setOpen] = useState(false);

  useEffect(() => {
    db.collection("expense_types").onSnapshot((qss) => {
      let temp = [];
      qss.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
      setExpenseTypes(temp);
      temp = [];
    });

    db.collection("payment_categories").onSnapshot((qss) => {
      let temp = [];
      qss.forEach((doc) => {
        temp.push({ ...doc.data(), id: doc.id });
      });
      setPaymentCategories(temp);
      temp = [];
    });
  }, []);

  const getFeesTermData = (cls) => {
    const allData = _.filter(
      fees_data,
      (fd) =>
        fd.class_admitted === cls &&
        fd.term_paid === term &&
        new Date(fd.date_paid).getFullYear() === year
    );
    const data = _.sortBy(allData, (dt) => dt.last_name);
    const amountPaid = _.sumBy(data, (dt) => parseFloat(dt.amount_paid));
    return [data, amountPaid, term, year, "School Fees"];
  };

  const getMonthsData = (data) => {
    let summary = [];
    if (category === "School Fees" || category === "Revenue") {
      const sortedData = _.sortBy(data, (sd) =>
        new Date(sd.date_paid).getTime()
      );
      const monthData = _.groupBy(sortedData, (dt) =>
        new Date(dt.date_paid).toLocaleString("default", { month: "long" })
      );

      Object.entries(monthData).forEach(([key, value]) => {
        summary.push({
          month: key,
          data: _.sumBy(value, (vd) => parseFloat(vd.amount_paid)),
        });
      });
    } else if (category === "Expenses") {
      const sortedData = _.sortBy(data, (sd) =>
        new Date(sd.actual_date).getTime()
      );
      const monthData = _.groupBy(sortedData, (dt) =>
        new Date(dt.actual_date).toLocaleString("default", { month: "long" })
      );

      Object.entries(monthData).forEach(([key, value]) => {
        summary.push({
          month: key,
          data: _.sumBy(value, (vd) => parseFloat(vd.amount)),
        });
      });
    } else if (category === "Payroll") {
      const temp = _.groupBy(data, dt => dt.month)
      Object.entries(temp).forEach(([key, value]) => {
        summary.push({
          month: key,
          data: _.sumBy(value, dt => parseInt(dt.total_spent)),
        });
      });
    }
    return summary;
  };

  const getExpenseTermData = (expense) => {
    const allData = _.filter(
      expenses,
      (exp) =>
        exp.exp_type === expense &&
        new Date(exp.date).getFullYear() === year &&
        exp.term === term
    );
    const data = _.sortBy(allData, (dt) => new Date(dt.actual_date).getTime());
    const totalAmount = _.sumBy(data, (dt) => parseFloat(dt.amount));

    return [data, totalAmount, year, term, expense];
  };

  const getPayroll = () => {
    const temp0 = _.filter(
      payroll,
      (slp) => slp.term === term && slp.year === year
    );

    const temp1 = _.sortBy(temp0, (tm) => new Date(tm.date_released).getTime());
    if (temp1.length < 1) {
      return [[], 0, {}];
    } else {
      //group payroll by month
      let monthData = [];
      const monthGroup = _.groupBy(temp1, (dt) => dt.month);
      Object.entries(monthGroup).forEach(([key, value]) => {
        monthData.push({
          month: key,
          entry: value,
        });
      });

      //get payroll by staff category
      const categoryData = [];
      for (var dt of monthData) {
        for (var d2 of dt.entry) {
          const temp = _.groupBy(d2.data, (st) => st.employee_category);
          //eslint-disable-next-line
          Object.entries(temp).forEach(([key, value]) => {
            categoryData.push({
              category: key,
              data: value,
              month: dt.month,
              date: d2.date_released,
            });
          });
        }
      }
      const data = [];
      const monthFromCategory = _.groupBy(categoryData, (st) => st.month);
      Object.entries(monthFromCategory).forEach(([key, value]) => {
        data.push({
          month: key,
          data: value,
        });
      });

      //total amount paid
      let totalSpent = _.sumBy(temp0, td => parseFloat(td.total_spent))
      return [data, totalSpent, temp0];
    }
  };

  const monthDataType = () => {
    switch (category) {
      case "School Fees":
        return getFeesTermData(cls)[0];
      case "Revenue":
        return getRevenueData(revenue)[0];
      case "Expenses":
        return getExpenseTermData(expense)[0];
      case "Payroll":
        return getPayroll()[2];
      default:
        return [];
    }
  };

  const getRevenueData = (rev) => {
    const allData = _.filter(
      addpayments,
      (pd) =>
        pd.class_admitted === cls &&
        pd.term_paid === term &&
        pd.fees_type === rev &&
        new Date(pd.date_paid).getFullYear() === year
    );

    const data = _.sortBy(allData, (rd) => new Date(rd.date_paid).getTime());
    const totalAmount = _.sumBy(data, (rd) => parseFloat(rd.amount_paid));

    return [data, totalAmount, term, year, rev];
  };

  const Data = () => {
    switch (category) {
      case "Revenue":
        return (
          <RevenueData
            cls={cls}
            revenue={revenue}
            data={getRevenueData(revenue)}
          />
        );
      case "School Fees":
        return <FeesData cls={cls} data={getFeesTermData(cls)} />;

      case "Payroll":
        return <PayrollData data={getPayroll()} />;

      case "Expenses":
        return (
          <ExpenseData expense={expense} data={getExpenseTermData(expense)} />
        );
      default:
        break;
    }
  };
  const panes = [
    {
      menuItem: "First Term",
      disabled: !category,
      render: () => (
        <Tab.Pane>
          <Data />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Second Term",
      disabled: !category,
      render: () => (
        <Tab.Pane>
          <Data />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Third Term",
      disabled: !category,
      render: () => (
        <Tab.Pane>
          <Data />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "First Semester",
      disabled: !category,
      render: () => (
        <Tab.Pane>
          <Data />
        </Tab.Pane>
      ),
    },
    {
      menuItem: "Second Semester",
      disabled: !category,
      render: () => (
        <Tab.Pane>
          <Data />
        </Tab.Pane>
      ),
    },
    
  ];

  const searchData = () => {
    const payments = [];
    const fees = [];
    for (var dt of fees_data) {
      fees.push({ ...dt, fees_type: "School Fees" });
    }

    for (var dt2 of expenses) {
      fees.push({
        ...dt2,
        fees_type: dt2.exp_type,
        date_paid: dt2.date,
        amount_paid: parseFloat(dt2.amount),
      });
    }

    const linkedFees = [...fees, ...addpayments];
    const groupedFees = _.groupBy(linkedFees, (fee) => new Date(fee.date_paid).toLocaleDateString());

    Object.entries(groupedFees).forEach(([key, value]) => {
      payments.push({
        date: new Date(key).toLocaleDateString("en-GB"),
        data: value
      });
    });
    
    return payments;
  };



  return (
    <Grid columns={2}>
      <Grid.Column computer={4} mobile={16}>
        <Segment raised style={{ minHeight: "50vh" }}>
          <Form>
            <Form.Field
              value={year}
              onChange={(e, target) => setYear(target.value)}
              control={Select}
              options={years}
              label="Select Year"
            />
            {category === "Revenue" ? (
              <Form.Field
                value={cls}
                onChange={(e, target) => setCls(target.value)}
                control={Select}
                options={all_classes}
                label="Select Class"
              />
            ) : null}
          </Form>
          <br />
          <br />
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Header as="h4">School Fees</Header>
            </AccordionSummary>
            <AccordionDetails>
              <List relaxed divided>
                {all_classes.map((cls, idx) => (
                  <List.Item
                    style={{ cursor: "pointer" }}
                    key={idx}
                    onClick={() => {
                      setCls(cls.value);
                      setCategory("School Fees");
                    }}
                  >
                    <List.Icon
                      name="graduation cap"
                      size="large"
                      color="olive"
                    />
                    <List.Content>
                      <List.Header content={cls.value} as="a" />
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Header as="h4">Expenses</Header>
            </AccordionSummary>
            <AccordionDetails>
              <List relaxed divided>
                {expenseTypes.map((cls, idx) => (
                  <List.Item
                    onClick={() => {
                      setCategory("Expenses");
                      setExpense(cls.type);
                    }}
                    key={idx}
                  >
                    <List.Icon name="money" size="large" color="blue" />
                    <List.Content>
                      <List.Header content={cls.type} as="a" />
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Header as="h4">Revenue (Other Fees)</Header>
            </AccordionSummary>
            <AccordionDetails>
              <List relaxed divided>
                {paymentCategories.map((pay, idx) => (
                  <List.Item
                    onClick={() => {
                      setCategory("Revenue");
                      setRevenue(pay.type);
                    }}
                    key={idx}
                  >
                    <List.Icon name="th" size="large" color="red" />
                    <List.Content>
                      <List.Header content={pay.type} as="a" />
                    </List.Content>
                  </List.Item>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>

          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Header as="h4">Payroll</Header>
            </AccordionSummary>
            <AccordionDetails>
              <List relaxed divided>
                <List.Item
                  onClick={() => {
                    setCategory("Payroll");
                    console.log(getPayroll()[2]);
                  }}
                >
                  <List.Icon name="calendar" size="large" color="red" />
                  <List.Content>
                    <List.Header content="View Payroll" as="a" />
                  </List.Content>
                </List.Item>
              </List>
            </AccordionDetails>
          </Accordion>
        </Segment>
      </Grid.Column>
      <Grid.Column computer={12} mobile={16}>
        <Segment raised>
          <Grid dividing columns={2}>
            <Grid.Column computer={11} mobile={16}>
              <Segment raised style={{ minHeight: "50vh" }}>
                <Tab
                  renderActiveOnly
                  menu={{
                    secondary: true,
                    pointing: true,
                    disabled: !category,
                  }}
                  panes={panes}
                  onTabChange={(e, data) =>
                    setTerm(panes[data.activeIndex].menuItem)
                  }
                />
              </Segment>
            </Grid.Column>

            <Grid.Column computer={5} mobile={16}>
              <Segment raised style={{ minHeight: "50vh" }}>
                <Button
                  onClick={() => setOpen(true)}
                  color="orange"
                  circular
                  floated="right"
                  icon="calendar"
                  size="mini"
                  title="Generate Report"
                />

                <Header
                  style={{ display: "inline" }}
                  content="Monthly Summary"
                  subheader="Overall Monthly Total"
                />
                <br />
                <br />

                <MonthSummary data={getMonthsData(monthDataType())} />
              </Segment>
            </Grid.Column>
          </Grid>
          <ReportModal open={open} setOpen={setOpen} data={searchData()}/>
        </Segment>
      </Grid.Column>
    </Grid>
  );
}
