import React from "react";
import {
  Form,
  Input,
  Button,
  Icon,
  Confirm,
  Header,
  Segment,
} from "semantic-ui-react";
import Feedback from "../data/feedback";
import { weeks } from "../data";
import uuid from "uuid";
import _ from "lodash";
import { db, auth } from "../services";

const INITIAL_STATE = {
  paid_by: "",
  amount_paid: "",
  receipt_id: "Not Generated",
  confirm_add: false,
};

const year = new Date().getFullYear();
export default class SchoolFeesPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receipt_id: uuid.v4().slice(0, 20),
      week_paid: "",
      day: new Date().toLocaleString("default", { weekday: "long" }),
      amount_paid: 0,
      received_by: auth().currentUser.displayName,
      paid_by: "",
      date_paid: new Date().toLocaleDateString(),
      opn: false,
      message: "",
      message_type: "",
      confirm_add: false,
    };
  }

  getTermPayments = (_id, _cls) => {
    let allPays = _.filter(this.props.feesData, (std) => {
      return (
        std.student_id === _id &&
        std.class_admitted === _cls &&
        std.term_paid === this.props.term &&
        new Date(std.date_paid).getFullYear() === year
      );
    });

    const amountPaid = _.sumBy(allPays, (sd) => parseFloat(sd.amount_paid));

    return amountPaid;
  };
  submitPayment = (event) => {
    const { other_names, last_name, student_id, class_admitted } =
      this.props.current_student;
    const {
      receipt_id,
      amount_paid,
      paid_by,
      date_paid,
      received_by,
      week_paid,
      day,
    } = this.state;

    const status = this.getTermPayments(student_id, class_admitted);



    if (status === 0) {
      let balance = this.props.class_fee - amount_paid;
      //there has been no payments for this particular term.
      db.collection("fee_payment")
        .add({
          other_names,
          last_name,
          student_id,
          class_admitted,
          received_by,
          week_paid,
          day,
          term_paid: this.props.term,
          receipt_id,
          amount_paid,
          paid_by,
          date_paid,
          balance: balance,
        })
        .then(() =>
          this.setState({
            ...INITIAL_STATE,
            opn: true,
            message: "Fee Payment Successful",
            message_type: "success",
          })
        ).catch(err => {
          this.setState({message:err.message, message_type:'error', opn:true})
        });
    } else {
      let balance =
        parseFloat(this.props.class_fee) - parseFloat(status + amount_paid);
      db.collection("fee_payment")
        .add({
          other_names,
          last_name,
          student_id,
          class_admitted,
          received_by,
          week_paid,
          day,
          term_paid: this.props.term,
          receipt_id: uuid.v4().slice(0, 20),
          amount_paid,
          paid_by,
          date_paid,
          balance: balance,
        })
        .then(() =>
          this.setState({
            ...INITIAL_STATE,
            opn: true,
            message: "Fee Payment Successful",
            message_type: "success",
          })
        ).catch(err => {
          this.setState({message:err.message, message_type:'error', opn:true})
        })
    }
    //check if paid this term and deduct.
  };

  closeFeed = (name) => {
    this.setState({ opn: false });
  };

  render() {
    const { class_admitted } = this.props.current_student;
    return (
      <Segment raised>
        <Confirm
          open={this.state.confirm_add}
          cancelButton={
            <Button icon color="red" size="mini" circular>
              <Icon name="cancel" /> No, Don't!{" "}
            </Button>
          }
          confirmButton={
            <Button icon color="green" size="mini" circular>
              <Icon name="checkmark" />
              {"  "}Yes, Continue{" "}
            </Button>
          }
          onConfirm={() =>
            this.setState({ confirm_add: false }, this.submitPayment)
          }
          onCancel={() => this.setState({ confirm_add: false })}
          size="mini"
          header="Payment Submission Confirm"
          content="This action will add Fee Details to Database. Continue ?"
        />

        <Header content="School Fees Payment" subheader={class_admitted} />
        <Form
          onSubmit={() => this.setState({ confirm_add: true })}
          inverted={this.props.mode}
        >
          <Form.Group>
            <Form.Select
              width={16}
              options={weeks}
              value={this.state.week_paid}
              name="week_paid"
              onChange={(e, target) =>
                this.setState({ week_paid: target.value })
              }
              label="Select Week"
              disabled={!this.props.current_student.student_id}
            />
          </Form.Group>

          <Form.Field
            control={Input}
            placeholder="Student ID"
            readOnly
            value={this.props.current_student.student_id}
            label="Student ID"
            required
          />
          {/* Name sides will be filled up selectionf from side list */}
          <Form.Group>
            <Form.Field
              width={16}
              control={Input}
              value={
                this.props.current_student.last_name +
                " " +
                this.props.current_student.other_names
              }
              readOnly
              placeholder="Student Name"
              required
              label="Name of Student"
            />
          </Form.Group>

          <Form.Group>
            <Form.Field
              width={6}
              value={this.state.amount_paid}
              control={Input}
              onChange={(e) =>
                this.setState({ amount_paid: parseFloat(e.target.value) })
              }
              type="number"
              min="1"
              placeholder="Amount Recieved"
              required
              label="Amount"
            />

            <Form.Field
              width={10}
              value={this.state.paid_by}
              control={Input}
              onChange={(e) => this.setState({ paid_by: e.target.value })}
              required
              placeholder="Jane Doe"
              label="Paid By"
            />
          </Form.Group>
          <Form.Field
            required
            control={Input}
            onChange={(e) => this.setState({ payers_phone: e.target.value })}
            minLength="10"
            maxLength="10"
            label="Target Phone"
          />
          <br />
          <Button
            type="submit"
            size="tiny"
            color={"orange"}
            disabled={
              this.props.disable_form ||
              !this.props.term ||
              !this.state.week_paid ||
              this.props.read_only === true
            }
          >
            Process Fee Payment
          </Button>
        </Form>
        <Feedback
          open={this.state.opn}
          message={this.state.message}
          message_type={this.state.message_type}
          close={() => this.setState({opn:false})}
        />
      </Segment>
    );
  }
}
