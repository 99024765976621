import React, { useState, useEffect } from "react";
import {
  Sidebar,
  Form,
  Button,
  Menu,
  Input,
  Header,
  Segment,
  List,
  Grid,
} from "semantic-ui-react";
import { db } from "../services";
import Feedback from "../data/feedback";
export default function Settings({ visible, closeSett }) {
  const [tax, setTax] = useState();
  const [ssnit, setSSNIT] = useState();
  const [bcharges, setBankCharge] = useState();
  const [adds, setAdditionals] = useState();
  const [paytype, setPayType] = useState();
  const [payamount, setPayAmount] = useState(0.0);
  const [expenseTypes, setExpenseType] = useState([]);
  const [paymentCategories, setPaymentCategories] = useState([]);
  const [exptype, setExpType] = useState("");
  const [opn, setOpn] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMType] = useState("");

  useEffect(() => {
    db.collection("app_settings")
      .doc("account_settings")
      .get()
      .then((doc) => {
        setSSNIT(doc.data().ssnit);
        setTax(doc.data().tax);
        setBankCharge(doc.data().bank_charges);
        setAdditionals(doc.data().additionals);
      });

    db.collection("expense_types").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      setExpenseType(temp_data);
      temp_data = [];
    });

    db.collection("payment_categories").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      setPaymentCategories(temp_data);
      temp_data = [];
    });
  }, []);

  const updateAccountSettings = (event) => {
    db.collection("app_settings")
      .doc("account_settings")
      .update({
        tax: parseFloat(tax),
        ssnit: parseFloat(ssnit),
        bank_charges: parseFloat(bcharges),
        additionals: parseFloat(adds),
        date_updated: new Date().toDateString(),
        at: new Date().toTimeString(),
      });
  };

  const addPayType = (e) => {
    e.preventDefault();
    db.collection("payment_categories")
      .add({
        type: paytype,
        amount: payamount,
        date_added: new Date().toDateString(),
        at: new Date().toTimeString(),
      })
      .then(() => {
        setOpn(true);
        setMsg("Payment Category Addition Successful ");
        setMType("success");
        setPayType("");
        setPayAmount(0.00)
      })
      .catch((err) => {
        setOpn(true);
        setMsg(err.message);
        setMType("error");
      });
  };

  const addExpType = (event) => {
    db.collection("expense_types")
      .add({
        type: exptype,
        date_added: new Date().toDateString(),
        at: new Date().toTimeString(),
      })
      .then(() => {
        setOpn(true);
        setMsg("Expense Type Addition ");
        setMType("success");
        setExpType("");
      })
      .catch((err) => {
        setOpn(true);
        setMsg(err.message);
        setMType("error");
      });
  };
  const deleteExpense = (_id) => {
    db.collection("expense_types")
      .doc(_id)
      .delete()
      .then(() => {
        setOpn(true);
        setMsg("Expense Type Deleted!");
        setMType("error");
      })
      .catch((err) => {
        setOpn(true);
        setMsg(err.message);
        setMType("error");
      });
  };
  const deletePayType = (_id) => {
    db.collection("payment_categories")
      .doc(_id)
      .delete()
      .then(() => {
        setOpn(true);
        setMsg("Payment Type Deleted!");
        setMType("error");
      })
      .catch((err) => {
        setOpn(true);
        setMsg(err.message);
        setMType("error");
      });
  };

  return (
    <>
      <Feedback
        open={opn}
        close={() => setOpn(false)}
        message={msg}
        message_type={msgType}
      />
      <Sidebar
        as={Menu}
        animation="overlay"
        onHide={() => closeSett()}
        direction="right"
        width="very wide"
        vertical
        visible={visible}
      >
        <Header
          style={{ padding: "10px" }}
          content="Application Settings"
          subheader="W.R.I.S"
        />
        <Menu.Item>
          <Form onSubmit={updateAccountSettings}>
            <Form.Group widths="equal">
              <Form.Field
                value={tax}
                type="number"
                control={Input}
                label="Income Tax"
                onChange={(e) => setTax(e.target.value)}
              />
              <Form.Field
                value={ssnit}
                type="number"
                control={Input}
                label="SSNIT"
                onChange={(e) => setSSNIT(e.target.value)}
              />
            </Form.Group>
            <Form.Group widths="equal">
              <Form.Field
                value={bcharges}
                control={Input}
                type="number"
                label="Bank Charges"
                onChange={(e) => setBankCharge(e.target.value)}
              />
              <Form.Field
                value={adds}
                control={Input}
                type="number"
                label="Additional"
                onChange={(e) => setAdditionals(e.target.value)}
              />
            </Form.Group>
            <Button type="submit" circular icon="send" color="green" />
          </Form>
        </Menu.Item>
        <Menu.Item>
          <Segment raised>
            <Grid columns={2}>
              <Grid.Column mobile={16}>
                <Segment raised>
                  <Header
                    
                    textAlign="left"
                    as="h3"
                    dividing
                    content="Add Payment Type"
                  />
                  

                  <Form onSubmit={addPayType}>
                  <Button
                    type="submit"
                    circular
                    disabled={!paytype || !payamount}
                    floated="right"
                    size="mini"
                    color="orange"
                    icon="send"
                  />
                    <Form.Group widths="2">
                      <Form.Field
                        required
                        label="Payment Type"
                        control={Input}
                        onChange={(e) => setPayType(e.target.value)}
                        value={paytype}
                      />

                      <Form.Field
                        required
                        label="Amount"
                        pattern="[0-9]+$"
                        control={Input}
                        onChange={(e) => setPayAmount(e.target.value)}
                        value={payamount}
                      />
                    </Form.Group>
                  </Form>

                  <List divided relaxed>
                    {paymentCategories.map((pd) => (
                      <List.Item key={pd._id}>
                        <List.Icon
                          onClick={() => deletePayType(pd._id)}
                          style={{ cursor: "pointer" }}
                          name="trash"
                          disabled
                          color="red"
                          size="small"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header as="h4">
                            {pd.type} - {pd.amount}¢{" "}
                          </List.Header>
                          <List.Description>{pd.date_added}</List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              </Grid.Column>
              <Grid.Column mobile={16}>
                <Segment raised>
                  <Header
                    textAlign="left"
                    as="h3"
                    dividing
                    content="Add Expense Type"
                  />
                  <Form onSubmit={addExpType}>
                    <Form.Field
                      value={exptype}
                      required
                      label="Expense Type"
                      control={Input}
                      onChange={(e) => setExpType(e.target.value)}
                    />
                  </Form>
                  <List divided relaxed>
                    {expenseTypes.map((exp) => (
                      <List.Item key={exp._id}>
                        <List.Icon
                          onClick={() => deleteExpense(exp._id)}
                          disabled
                          style={{ cursor: "pointer" }}
                          name="trash"
                          color="red"
                          size="small"
                          verticalAlign="middle"
                        />
                        <List.Content>
                          <List.Header as="h4">{exp.type}</List.Header>
                          <List.Description>{exp.date_added}</List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </List>
                </Segment>
              </Grid.Column>
            </Grid>
          </Segment>
        </Menu.Item>
      </Sidebar>
    </>
  );
}
