import React, { useState } from 'react'
import { Form, Segment, Button, Confirm, Header } from 'semantic-ui-react'
import { db, auth } from '../services'
import uuid from 'uuid'
import Feedback from '../data/feedback'
import {weeks} from '../data'
import _ from 'lodash'

export default function OtherPayments({ pInfo, read_only, categories, term, data }) {

    const [info, setInfo] = useState('')
    const [paid_by, setPayer] = useState('')
    const [amount, setAmount] = useState(0)
    const [confirm, setConfirm] = useState(false)
    const [opn, setOpn] = useState(false)
    const [message, setMessage] = useState('')
    const [week, setWeek] = useState('')
    const [mType, setMType] = useState('success')

    const getBalance = (amt) => {
        
        const payStandard = _.find(categories,ct => ct.value === info).amount
        const history = _.find(data, dt => dt.type === info)
        const dataByCategory = history === undefined ? [] : history.data

        const payments = _.filter(dataByCategory, dt => dt.student_id === pInfo.student_id) 
        const total = _.sumBy(payments, pd => parseInt(pd.amount_paid))
        console.log(history, total, pInfo)
        return parseInt(payStandard)  - (parseInt(total) + parseInt(amt))
    }
    const postInfo = () => {

        db.collection('other_payments').add({
            fees_type: info,
            term_paid: term,
            week_paid: week,
            class_admitted: pInfo.class_admitted,
            last_name: pInfo.last_name,
            other_names: pInfo.other_names,
            student_id:pInfo.student_id,
            amount_paid: parseFloat(amount),
            balance: parseFloat(getBalance(amount)),
            received_by: auth().currentUser.displayName,
            paid_by: paid_by,
            date_paid: new Date().toDateString(),
            receipt_id: uuid.v4().slice(0, 20)
        }).then(() => {
            setMessage(`${info} Payment Successful`); setMType('success'); setOpn(true)
        }).catch(err => {
            setMessage(err.message); setMType('error'); setOpn(true)
        })
    }
    return (
        <Segment raised>
            <Confirm
            size="tiny"
                open={confirm}
                closeOnDimmerClick={false}
                header="Confirm Payment"
                content="This action will add Payment Information. Continue ?"
                onCancel={() => setConfirm(false)}
                onConfirm={() => { setConfirm(false); postInfo() }}
                cancelButton={<Button size='tiny' content="No,Cancel" color="red" />}
                confirmButton={<Button size='tiny' content="Yes, Continue" color="green" />}
            />
                <Header content="Additional Fees" subheader={pInfo.class_admitted} />

            <Form onSubmit={(e) => setConfirm(true)}>

                <Form.Input label="Student Name" value={pInfo.other_names + " " + pInfo.last_name} readOnly />

                <Form.Select label="Payment Type" value={info} options={categories}
                 required onChange={(e, target) => setInfo(target.value)} />
                <Form.Select label="Select Week" value={week} options={weeks} onChange={(e, target) => setWeek(target.value)} />
  

                <Form.Group widths="2">
                <Form.Input width={8} type="number" label="Amount" value={amount} onChange={(e) => setAmount(e.target.value)} />
                <Form.Input label="Paid By" value={paid_by} onChange={(e) => setPayer(e.target.value)} />
                </Form.Group>
                <br />
                <Button disabled={!term || !week || !info || read_only === true} color="teal" size="tiny" content="Add Payment" />
                {/* new Date().getDay() === 0 || new Date().getDay() === 6 || */}
            </Form>
            <Feedback open={opn} message={message} close={() => setOpn(false)} message_type={mType} />

        </Segment>
    )
}