var pdfMake = require("pdfmake/build/pdfmake.js");
var pdfFonts = require("pdfmake/build/vfs_fonts.js");
pdfMake.vfs = pdfFonts.pdfMake.vfs;
const { hcover } = require("../images");

export default function generate_history(cls, data) {
  var rows = [];
  rows.push([
    { text: cls.toUpperCase(), colSpan: 4 },
    {},
    {},
    {},
    { text: "Total :¢" + data[1] },
  ]);
  rows.push([
    { text: "Name", bold: true },
    { text: "Amount", bold: true },
    { text: "Balance", bold: true },
    { text: "Date Paid", bold: true },
    { text: "Paid By", bold: true },
  ]);
  for (var dt of data[0]) {
    rows.push([
      dt.other_names + " " + dt.last_name,
      dt.amount_paid,
      dt.balance,
      new Date(dt.date_paid).toLocaleDateString(),
      dt.paid_by,
    ]);
  }

  const docDefinition = {
    pageOrientation: "potrait",
    paperSize: "A4",
    pageMargins: [15, 15, 15, 15],
    content: [
      {
        image: hcover,
        width: 595,
        absolutePosition: { x: 0, y: 0 },
      },
      {
        text: data[4],
        absolutePosition: { x: 136, y: 55 },
      },
      {
        text: data[2],
        absolutePosition: { x: 342, y: 55 },
      },
      {
        text: data[3],
        absolutePosition: { x: 530, y: 55 },
      },
      {
        absolutePosition: { x: 30, y: 100 },
        table: {
		  widths:[200,50,50,70,120],
          body: rows,
        },
      },
    ],
  };

  return pdfMake.createPdf(docDefinition).print();
}
