import React from "react";
import { Grid, Segment, Menu, Icon, Header, Button } from "semantic-ui-react";
import { Settings } from "../components";
import SideBar from "./side-bar";
import { db, auth } from "../services";

export default class Layout extends React.Component {
  constructor() {
    super();
    this.state = {
      open: false,
      show_settings: false,
      students: [],
      settings: {},
      account_settings: {},
      payroll: [],
      staff: [],
      fees: [],
      expenses: [],
      other_fees: [],
    };
  }
  componentDidMount() {
    db.collection("staff_registration").onSnapshot((qss) => {
      let staff_data = [];
      qss.forEach((doc) => {
        const data = doc.data();
        if (
          (data.status === undefined || data.status === "active") 
        ) {
          staff_data.push({ ...data, _id: doc.id });
        }
      });
      this.setState({ staff: staff_data }, () => (staff_data = []));
    });

    db.collection("student_registration").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        if (doc.data().status === undefined || doc.data().status === "active") {
          temp_data.push({ ...doc.data(), _id: doc.id });
        }
      });
      this.setState({ students: temp_data }, () => (temp_data = []));
    });

    db.collection("payroll").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ payroll: temp_data }, () => (temp_data = []));
    });

    db.collection("expenses").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ expenses: temp_data }, () => (temp_data = []));
    });

    db.collection("other_payments").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ other_fees: temp_data }, () => (temp_data = []));
    });

    db.collection("fee_payment").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ ...doc.data(), _id: doc.id });
      });
      this.setState({ fees: temp_data }, () => (temp_data = []));
    });

    db.collection("app_settings")
      .doc("sch_settings")
      .onSnapshot((doc) => {
        doc.exists
          ? this.setState({ settings: { ...doc.data() } })
          : this.setState({ settings: { dark_mode: false } });
      });

    db.collection("app_settings")
      .doc("account_settings")
      .get()
      .then((res) => {
        this.setState({ account_settings: res.data() });
      });
  }
  render() {
    const data = {
      expenses: this.state.expenses,
      addpayments: this.state.other_fees,
      sett: this.state.settings,
      fees_data: this.state.fees,
      students: this.state.students,
      staff: this.state.staff,
      finance_sett: this.state.account_settings,
      payroll: this.state.payroll,
    };

    const { Child } = this.props;
    return (
      <React.Fragment>
        <SideBar
          closeState={(bool) => this.setState({ open: false })}
          open={this.state.open}
          newState={(name) => this.setState({ view: name })}
        />
        <Settings
          visible={this.state.show_settings}
          closeSett={() => {
            this.setState({ show_settings: false });
          }}
        />
        <Menu fixed="top" borderless>
          {/* User Icon, Toggles, modes */}
          <Menu.Item>
            <Header
              as="h3"
              textAlign="left"
              content="W.R.I.S"
              subheader="Accounts"
            />
          </Menu.Item>

          <Menu.Menu position="right">
            <Menu.Item
              as="a"
              onClick={() => this.setState({ show_settings: true })}
            >
              <Icon name="cog" size="large" />
            </Menu.Item>

            <Menu.Item>
              <Header
                image={auth().currentUser.photoURL}
                as="h4"
                textAlign="left"
                content={auth().currentUser.displayName ?? "Accountant"}
                subheader="Accounts"
              />
            </Menu.Item>

            <Menu.Item
              onClick={() => this.setState({ open: !this.state.open })}
            >
              <Icon name="bars" size="large" />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
        <Segment raised style={{ minHeight: "100vh" }}>
          <Grid>
            <Grid.Column computer={16} mobile={16} tablet={16}>
              <br />
              <br />
              <Child {...data} />
            </Grid.Column>
          </Grid>
        </Segment>

        <Menu fixed="bottom" borderless size="mini">
          {/* Current Tabs, Contact Information */}
          <Menu.Item>
            
            <p>&copy; {new Date().getFullYear()}{" "} | Logicians Ghana</p>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button
              onClick={() => auth().signOut()}
                circular
                icon="power"
                size="mini"
                color="red"
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </React.Fragment>
    );
  }
}
