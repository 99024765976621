import React from "react";
import { Icon, Sidebar, Menu } from "semantic-ui-react";
import { Link } from "react-router-dom";
export default function SideBar({ open, closeState }) {
  return (
    <Sidebar
      direction="left"
      animation="overlay"
      icon="labeled"
      onHide={() => closeState(false)}
      vertical
      visible={open}
      width="thin"
      as={Menu}
    >
      <Link to="/dashboard">
        <Menu.Item>
          <Icon name="chart line" />
          Dashboard
        </Menu.Item>
      </Link>

      <Link to="/payroll">
        <Menu.Item>
          <Icon.Group>
            <Icon name="group" size="big" />
            <Icon name="th" corner />
          </Icon.Group>
          <br />
          Payroll
        </Menu.Item>
      </Link>

      <Link to="/payments">
        <Menu.Item>
          <Icon name="money" />
          Payments
        </Menu.Item>
      </Link>

      <Link to="/expenses">
        <Menu.Item>
          <Icon name="cart" />
          Expenses
        </Menu.Item>
      </Link>

      <Link to="/school-fees">
        <Menu.Item>
          <Icon name="folder open" />
          School Fees
        </Menu.Item>
      </Link>
      
      <Link to="/additional-fees">
      <Menu.Item>
        <Icon name="dashboard" />
        Other Fees
      </Menu.Item>
      </Link>

      <Link to="/standards">
        <Menu.Item>
          <Icon name="table" />
          Standards
        </Menu.Item>
      </Link>

      <Link to="/records">
        <Menu.Item>
          <Icon name="folder open" />
          Reports
        </Menu.Item>
      </Link>
    </Sidebar>
  );
}
