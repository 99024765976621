import _ from "lodash";
export const generateStatus = ({ data, division, amount }) => {
  const fullPaid = [],
    partPaid = [],
    noPaid = [],
    allPays = [],
    allPaidIDs = [];

  let allPayTerm = _.groupBy(data, (sd) => sd.student_id);

  Object.entries(allPayTerm).forEach(([key, value]) => {
    const pI = _.find(division, (sd) => sd.student_id === key);

    allPays.push({
      student_id: key,
      name: value[0].last_name + " " + value[0].other_names,
      photo: pI === undefined ? "" : pI.profile_image,
      amount_paid: _.sumBy(value, (amt) => parseFloat(amt.amount_paid)),
    });
    allPaidIDs.push(key);
  });

  for (const std of allPays) {
    if (std.amount_paid >= amount) {
      fullPaid.push({
        ...std,
        balance: parseFloat(amount - std.amount_paid),
        status: "Full Payment",
      });
    }

    if (std.amount_paid <= amount) {
      partPaid.push({
        ...std,
        balance: parseFloat(amount - std.amount_paid),
        status: "Part Payment",
      });
    }
  }

  for (const dt of division) {
    if (!allPaidIDs.includes(dt.student_id)) {
      noPaid.push({
        name: dt.other_names + " " + dt.last_name,
        student_id: dt.student_id,
        photo: dt.profile_image,
        amount_paid: 0.0,
        balance: amount,
        status: "No Payment",
      });
    }
  }

  return {
    fullPaid: _.sortBy(fullPaid, (fp) => fp.name),
    partPaid: _.sortBy(partPaid, (pp) => pp.name),
    noPaid: _.sortBy(noPaid, (np) => np.name),
    ids: allPaidIDs,
  };
};
