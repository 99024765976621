import React from "react";
import {
  Grid,
  Segment,
  Confirm,
  List,
  Image,
  Item,
  Table,
  Label,
  Button,
  Form,
  Select,
  Header,
} from "semantic-ui-react";
import _ from "lodash";
import { db, auth } from "../services";
import { months, years } from "../data";
import Feedback from "../data/feedback";
import generate_payslip from "../generators/generate_payslip";
export default class Payroll extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current_employee: null,
      year: new Date().getFullYear(),
      payroll: [],
      month: months[new Date().getMonth()].value,
      term: props.sett.current_term,
      semester: props.sett.current_semester,
      confirm_upload: false,
      opn: false,
      message: "",
      message_type: "",
      id: "",
    };
  }

  componentDidMount() {
    db.collection("payroll").onSnapshot((qss) => {
      let temp_data = [];
      qss.forEach((doc) => {
        temp_data.push({ _id: doc.id, ...doc.data() });
      });
      this.setState({ payroll: temp_data }, () => (temp_data = []));
    });
  }
  getStaff = () => {
    const filteredStaff = _.filter(
      this.props.staff,
      (sd) => sd.payroll_status === "Active" || sd.payroll_status === undefined
    );
    let categories = _.groupBy(filteredStaff, (sd) => sd.employee_category);
    let new_arr = [];
    Object.entries(categories).forEach(([key, value]) => {
      new_arr.push({ category: key, data: _.sortBy(value, vd => vd.last_name) });
    });

    return new_arr;
  };

  getPayrollHistory = (staff_id) => {
    const data = [];
    for (var dt of this.state.payroll) {
      for (var stf of dt.data) {
        if (stf.staff_id === staff_id) {
          data.push({
            date: dt.date_released,
            term: dt.term,
            data: stf,
            year: dt.year,
            month: dt.month,
            released_by: dt.released_by,
            standard: dt.standards,
          });
        }
      }
    }
    return _.sortBy(data, (dt) => new Date(dt.date).getTime());
  };

  getSinglePrint = (id) => {
    const allData = this.getPayrollHistory(id);
    return _.find(
      allData,
      (sd) => sd.month === this.state.month && sd.year === this.state.year
    );
  };

  uploadPaySlip = () => {
    //check if doc exists.
    const doc_name = this.state.month + "_" + new Date().getFullYear();
    const status = _.find(this.state.payroll, (pr) => pr._id === doc_name);
    if (status === undefined) {
      const data = this.preparePaySlip();

      const deductions = _.sumBy(data, (dt) => parseFloat(dt.deduction ?? 0.0));
      const totalSpent = _.sumBy(data, (dt) => parseFloat(dt.gross_salary));

      db.collection("payroll")
        .doc(doc_name)
        .set({
          year: new Date().getFullYear(),
          month: this.state.month,
          data: data,
          term: this.state.term,
          total_spent: parseFloat(totalSpent) - parseFloat(deductions),
          semester: this.state.semester,
          date_released: new Date().toGMTString(),
          time_released: new Date().toLocaleTimeString(),
          released_by: auth().currentUser.displayName,
          standards: this.props.finance_sett,
        })
        .then(() => {
          this.setState({
            opn: true,
            message: "Payslips uploaded successfully!",
            message_type: "success",
          });
        })
        .catch((err) => {
          this.setState({
            opn: true,
            message: err.message,
            message_type: "error",
          });
        });
    } else {
      this.setState({
        opn: true,
        message: "Data Already Exists",
        message_type: "warning",
      });
    }
  };

  getPayDetails = (salary) => {
    const { tax, bank_charges, additionals, ssnit } = this.props.finance_sett;

    const tax_charges = Number(parseFloat(tax / 100) * salary).toFixed(2);
    const ssnit_charges = Number(parseFloat(ssnit / 100) * salary).toFixed(2);
    const bcharges = Number(parseFloat(bank_charges / 100) * salary).toFixed(2);
    const adds = Number(parseFloat(additionals / 100) * salary).toFixed(2);
    const net =
      parseFloat(salary) -
      parseFloat(
        parseFloat(tax_charges) +
          parseFloat(ssnit_charges) +
          parseFloat(bcharges) +
          parseFloat(adds)
      ).toFixed(2);

    return [
      parseFloat(tax_charges),
      parseFloat(ssnit_charges),
      parseFloat(bcharges),
      parseFloat(adds),
      parseFloat(net),
    ];
  };

  preparePaySlip = () => {
    let slips = [];
    let staffs = this.getStaff();

    //eslint-disable-next-line
    staffs.map((sd) => {
      //eslint-disable-next-line
      sd.data.map((dt) => {
        slips.push({
          staff_id: dt.staff_id,
          employee_category: sd.category,
          date_joined: dt.date_registered,
          date_released: new Date().toLocaleDateString(),
          phone: dt.phone,
          bonus: dt.bonus ?? 0.0,
          title: dt.title,
          last_name: dt.last_name,
          first_name: dt.first_name,
          gross_salary: dt.gross_salary,
          deductions: dt.deduction ?? 0.0,
          tax_charges: this.getPayDetails(dt.gross_salary)[0],
          ssnit_charges: this.getPayDetails(dt.gross_salary)[1],
          bank_charges: this.getPayDetails(dt.gross_salary)[2],
          other_charges: this.getPayDetails(dt.gross_salary)[3],
          net_salary:
            this.getPayDetails(dt.gross_salary)[4] - (dt.deduction ?? 0.0),
        });
      });
    });
    return slips;
  };

  getHistorySlips = () => {
    const temp1 = _.find(
      this.state.payroll,
      (slp) => slp.month === this.state.month && slp.year === this.state.year
    );

    if (temp1 === undefined) {
      return [];
    } else {
      let temp = _.groupBy(temp1.data, (rd) => rd.employee_category);
      let data = [];
      Object.entries(temp).forEach(([key, value]) => {
        data.push({
          category: key,
          data: value,
          id: temp1._id,
        });
      });

      return data;
    }
  };

  deletePayroll = (id) => {
    db.collection("payroll")
      .doc(id)
      .delete()
      .then(() => {
        this.setState({
          message: "Deletion Successful",
          message_type: "success",
          opn: true,
        });
      })
      .catch((err) => {
        this.setState({
          message: err.message,
          message_type: "error",
          opn: true,
        });
      });
  };

  render() {
    const { current_employee } = this.state;
    return (
      <>
        <Confirm
          size="tiny"
          open={this.state.confirm_upload}
          closeOnDimmerClick={false}
          header="Payroll Data Upload"
          content="This action will upload Payroll Information, Continue ?"
          onCancel={() => this.setState({ confirm_upload: false })}
          onConfirm={() => {
            this.setState({ confirm_upload: false }, () =>
              this.uploadPaySlip()
            );
          }}
          cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
          confirmButton={
            <Button size="tiny" content="Yes, Continue" color="green" />
          }
        />

        <Confirm
          size="tiny"
          open={this.state.confirm_delete}
          closeOnDimmerClick={false}
          header="Payroll Data Delete!"
          content="This action will delete Payroll Information, Continue ?"
          onCancel={() => this.setState({ confirm_delete: false })}
          onConfirm={() => {
            this.setState({ confirm_delete: false }, () =>
              this.deletePayroll(this.state.id)
            );
          }}
          cancelButton={<Button size="tiny" content="No,Cancel" color="red" />}
          confirmButton={
            <Button size="tiny" content="Yes, Continue" color="green" />
          }
        />

        <Grid columns="2">
          <Feedback
            open={this.state.opn}
            close={() => this.setState({ opn: false })}
            message={this.state.message}
            message_type={this.state.message_type}
          />
          <Grid.Column computer="5" mobile={16}>
            <Segment raised>
              <Header content="Employees" subheader="By Category" />
              <List divided relaxed>
                {this.getStaff().map((std, idx) => (
                  <React.Fragment key={idx}>
                    <Label ribbon size="tiny" color="orange">
                      {std.category}s
                    </Label>
                    <br />
                    <br />
                    {std.data.map((sd, idx) => (
                      <List.Item key={idx}>
                        <Image avatar src={sd.profile_image} />
                        <List.Content
                          onClick={() =>
                            this.setState({ current_employee: sd })
                          }
                        >
                          <List.Header as="h4" style={{ cursor: "pointer" }}>
                            {sd.last_name + " " + sd.first_name}
                          </List.Header>
                          <List.Description>
                            Gross Salary:{sd.gross_salary}
                          </List.Description>
                        </List.Content>
                      </List.Item>
                    ))}
                  </React.Fragment>
                ))}
              </List>
            </Segment>
          </Grid.Column>
          <Grid.Column computer="11" mobile={16}>
            <Segment raised>
              {current_employee === null ? (
                <>
                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field
                        onChange={(e, target) =>
                          this.setState({ month: target.value })
                        }
                        value={this.state.month}
                        control={Select}
                        options={months}
                        label="Select Month"
                      />
                      <Form.Field
                        onChange={(e, target) =>
                          this.setState({ year: target.value })
                        }
                        value={this.state.year}
                        control={Select}
                        options={years}
                        label="Select Year"
                      />
                    </Form.Group>
                  </Form>
                  <Table celled striped unstackable fixed singleLine>
                    <Table.Header>
                      <Table.Row textAlign="center">
                        <Table.HeaderCell>Name</Table.HeaderCell>
                        <Table.HeaderCell>Gross</Table.HeaderCell>
                        <Table.HeaderCell>Tax</Table.HeaderCell>
                        <Table.HeaderCell>SSNIT</Table.HeaderCell>
                        <Table.HeaderCell>Charges</Table.HeaderCell>
                        <Table.HeaderCell>Others</Table.HeaderCell>
                        <Table.HeaderCell>Net Salary</Table.HeaderCell>
                        {this.getHistorySlips().length < 1 ? null : (
                          <Table.HeaderCell>Actions</Table.HeaderCell>
                        )}
                      </Table.Row>
                    </Table.Header>
                    {this.getHistorySlips().length < 1 ? (
                      <Table.Body>
                        {this.getStaff().map((st, idx) => (
                          <React.Fragment key={idx}>
                            <br />
                            <Label ribbon size="small" color="orange">
                              {st.category}s
                            </Label>
                            <br />
                            <br />
                            {st.data.map((sd) => (
                              <Table.Row textAlign="center" key={sd._id}>
                                <Table.Cell>
                                  {sd.last_name + " " + sd.first_name}
                                </Table.Cell>
                                <Table.Cell>{sd.gross_salary}</Table.Cell>
                                <Table.Cell>
                                  {this.getPayDetails(sd.gross_salary)[0]}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.getPayDetails(sd.gross_salary)[1]}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.getPayDetails(sd.gross_salary)[2]}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.getPayDetails(sd.gross_salary)[3]}
                                </Table.Cell>
                                <Table.Cell>
                                  {this.getPayDetails(sd.gross_salary)[4]}
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </React.Fragment>
                        ))}
                      </Table.Body>
                    ) : (
                      <Table.Body>
                        {this.getHistorySlips().map((slp, idx) => (
                          <React.Fragment key={idx}>
                            <Table.Row>
                              <Table.Cell colSpan="6">
                                <Label ribbon size="small" color="orange">
                                  {slp.category}s
                                </Label>
                              </Table.Cell>
                              <Table.Cell colSpan="2">
                                <Label
                                  ribbon="right"
                                  size="small"
                                  icon="trash"
                                  as="button"
                                  color="orange"
                                  onClick={() =>
                                    this.setState({
                                      confirm_delete: true,
                                      id: slp.id,
                                    })
                                  }
                                />
                              </Table.Cell>
                            </Table.Row>

                            {slp.data.map((dt, idx) => (
                              <Table.Row key={idx}>
                                <Table.Cell>
                                  {dt.last_name + " " + dt.first_name}
                                </Table.Cell>
                                <Table.Cell>{dt.gross_salary}</Table.Cell>
                                <Table.Cell>{dt.tax_charges}</Table.Cell>
                                <Table.Cell>{dt.ssnit_charges}</Table.Cell>
                                <Table.Cell>{dt.bank_charges}</Table.Cell>
                                <Table.Cell>{dt.other_charges}</Table.Cell>
                                <Table.Cell>{dt.net_salary}</Table.Cell>
                                <Table.Cell textAlign="center">
                                  <Button
                                    circular
                                    color="blue"
                                    onClick={() =>
                                      generate_payslip(
                                        this.getSinglePrint(dt.staff_id)
                                      )
                                    }
                                    size="mini"
                                    title="Print"
                                    icon="print"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </React.Fragment>
                        ))}
                      </Table.Body>
                    )}
                    <Table.Footer>
                      <Table.Row>
                        <Table.HeaderCell
                          colSpan={
                            this.getHistorySlips().length < 1 ? "7" : "8"
                          }
                        >
                          <Button
                            floated="right"
                            onClick={() =>
                              this.setState({ confirm_upload: true })
                            }
                            circular
                            size="mini"
                            color="orange"
                            icon="send"
                            title="Release Report"
                          />

                          <Button
                            size="mini"
                            disabled
                            floated="right"
                            circular
                            color="blue"
                            icon="download"
                            title="Print"
                          ></Button>
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Footer>
                  </Table>
                </>
              ) : (
                <Item.Group>
                  <Item>
                    <Item.Image
                      size="small"
                      src={current_employee.profile_image}
                    />

                    <Item.Content>
                      <Item.Header>
                        {current_employee.last_name +
                          " " +
                          current_employee.first_name}
                      </Item.Header>
                      <Button
                        circular
                        icon="th"
                        size="mini"
                        color="orange"
                        floated="right"
                        onClick={() =>
                          this.setState({ current_employee: null })
                        }
                      />
                      <Item.Meta>
                        <span>{current_employee.employee_category}</span>
                      </Item.Meta>
                      <Item.Description>
                        <Table celled striped unstackable fixed singleLine>
                          <Table.Header>
                            <Table.Row textAlign="center">
                              <Table.HeaderCell>Month</Table.HeaderCell>
                              <Table.HeaderCell>Gross</Table.HeaderCell>
                              <Table.HeaderCell>Net</Table.HeaderCell>
                              <Table.HeaderCell>Date</Table.HeaderCell>
                              <Table.HeaderCell>Year</Table.HeaderCell>
                              <Table.HeaderCell>Actions</Table.HeaderCell>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {this.getPayrollHistory(
                              current_employee.staff_id
                            ).map((dt, idx) => (
                              <Table.Row textAlign="center" key={idx}>
                                <Table.Cell>{dt.month}</Table.Cell>
                                <Table.Cell>¢{dt.data.gross_salary}</Table.Cell>
                                <Table.Cell>¢{dt.data.net_salary}</Table.Cell>
                                <Table.Cell>
                                  {new Date(dt.date).toDateString()}
                                </Table.Cell>
                                <Table.Cell>{dt.year}</Table.Cell>
                                <Table.Cell>
                                  <Button
                                    onClick={() => generate_payslip(dt)}
                                    size="tiny"
                                    circular
                                    icon="print"
                                    color="green"
                                  />
                                </Table.Cell>
                              </Table.Row>
                            ))}
                          </Table.Body>
                        </Table>
                      </Item.Description>
                    </Item.Content>
                  </Item>
                </Item.Group>
              )}
            </Segment>
          </Grid.Column>
        </Grid>
      </>
    );
  }
}
