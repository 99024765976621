import { Route, Redirect } from "react-router-dom";
import Layout from "../layout";

export const PrivateRoute = ({ component: Component, auth, ...misc }) => {
  return(
    <Route
      {...misc}
      render={(props) => auth ? 
      <Layout Child={Component} {...props} /> : <Redirect to={{pathname:'/login'}} /> }
    />
  )
};

export const PublicRoute = ({ component: Component, auth, ...misc }) => {
   return (
     <Route 
     {...misc}
     render={(props) => auth ? 
      <Redirect to={{pathname:'/dashboard'}} /> : <Component {...props}/>

    } />
   )
};
