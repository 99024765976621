import React from 'react'
import MUIDataTable from 'mui-datatables' 
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

export default function SchoolFees({fees_data, title}){
    
    const getMuiTheme = () =>
    createMuiTheme({
      overrides: {
        MUIDataTable: {
          root: {
            backgroundColor: 'green',
            
          },
          paper: {
            boxShadow: 'none',
          },
        },
        MuiToolbar: {
          root: {
            backgroundColor: 'green',
            fontFamily:'Sen'
          },
        },
        MuiTableRow:{
            head:{
                fontFamily:"Sen"
            }
        },
        MuiTableCell: {
          head: {
              
            backgroundColor: 'purple',
            fontFamily:'Sen'
          },
        },
        MuiTableFooter: {
          root: {
            '& .MuiToolbar-root': {
              backgroundColor: 'white',
            },
          },
        },
      },
    });
        const fees_columns = [
            {
                name:"receipt_id", 
                label:"Receipt ID",
                options:{
                    filter:false,
                    sort:false
                }
            },
            {
                name:"last_name", 
                label:"Last Name",
                options:{
                    filter:true,
                    sort:true,
                }
            },
            {
                name:"other_names", 
                label:"Other Name",
                options:{
                    filter:true,
                    sort:true,
                }
            },
            {
                name:"class_admitted", 
                label:"Class Admitted",
                options:{
                    filter:true,
                    sort:true,
                    setCellHeaderProps: value => ({ style: { fontFamily:'Sen !important' } }),

                }
            },
            {
                name:"amount_paid", 
                label:"Amount",
                options:{
                    filter:true,
                    sort:true
                }
            },
            
            {
                name:"balance",
                label:"Balance",
                options:{
                    filter:true, 
                    sort:true
                }
            },
            {
                name:"term_paid", 
                label:"Term",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"date_paid", 
                label:"Date Paid",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"paid_by", 
                label:"Paid By",
                options:{
                    filter:true,
                    sort:true
                }
            },
            {
                name:"received_by",
                label:"Paid To"
            }

        ]

        return(
            <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable 
            title={"School Fees"}
            columns={fees_columns}
            data={fees_data}
            options={{
                selectableRows:'none',
                fixedHeader:true,
                responsive:'standard'
            }}
            />
            </MuiThemeProvider>
        )
    }  
