import React, { useState } from "react";
import {
  Segment,
  Table,
  Button,
  Input,
  Grid,
  Header,
  Form,
  Dropdown,
  List,
  Image,
  Confirm,
  Icon,
} from "semantic-ui-react";
import _ from "lodash";
import { db } from "../services";
import Feedback from "../data/feedback";

export default function Standards({ staff, finance_sett }) {
  const [active, setActive] = useState(false);
  const [profile, setProfile] = useState({});
  const [opn, setOpn] = useState(false);
  const [msg, setMsg] = useState("");
  const [msgType, setMsgT] = useState("");
  const [gross_salary, setGrossSalary] = useState(0.0);
  const [bonus, setBonus] = useState(0.0);
  const [deduction, setDeduction] = useState(0.0);
  const [confirm, setConfirm] = useState(false);

  const fieldStaff = () => {
    let _staff = [];

    //eslint-disable-next-line
    staff.map((sd, idx) => {
      _staff.push({
        text: sd.last_name + " " + sd.first_name,
        key: sd.staff_id,
        value: sd,
        disabled: sd.payroll_status === "In Active",
        image: sd.profile_image,
      });
    });
    return _staff;
  };
  const updateStaffSalary = (event) => {
    db.collection("staff_registration")
      .doc(profile._id)
      .update({
        gross_salary: gross_salary,
        bonus: bonus,
        deduction: deduction,
        salary_change_date: new Date().toDateString(),
        salary_change_time: new Date().toTimeString(),
      })
      .then((resp) => {
        setOpn(true);
        setMsg(
          `${
            profile.last_name + " " + profile.first_name
          } salary fields update Successful`
        );
        setMsgT("success");
      })
      .catch((err) => {
        setMsg(err.message);
        setMsgT("error");
      });
  };

  const changeStatus = (id, change) => {
    db.collection("staff_registration")
      .doc(id)
      .update({ payroll_status: change })
      .then(() => {
        setMsg(`Staff Payroll Status Changed to ${change}`);
        setOpn(true);
        setMsgT("success");
        fieldStaff();
      })
      .catch((err) => {
        setMsg(err.message);
        setOpn(true);
        setMsgT("error");
      });
  };

  const filteredStaff = _.filter(
    staff,
    (sd) => sd.payroll_status === "Active" || sd.payroll_status === undefined
  );

  const setData = (id) => {
    const data = _.find(staff, (std) => std.staff_id === id);
    if (data === undefined) {
      setGrossSalary(0.0);
      setBonus(0.0);
      setDeduction(0.0);
    } else {
      setGrossSalary(data.gross_salary ?? 0.00);
      setBonus(data.bonus ?? 0.00);
      setDeduction(data.deduction ?? 0.00);
    }
  };
  return (
    <Segment raised>
      <Feedback
        open={opn}
        close={() => setOpn(false)}
        message={msg}
        message_type={msgType}
      />

      <Confirm
        open={confirm}
        cancelButton={
          <Button icon color="red" size="mini" circular>
            <Icon name="cancel" /> No, Don't!{" "}
          </Button>
        }
        confirmButton={
          <Button icon color="green" size="mini" circular>
            <Icon name="checkmark" />
            {"  "}Yes, Continue{" "}
          </Button>
        }
        onConfirm={() => {
          setConfirm(false);
          updateStaffSalary();
        }}
        onCancel={() => setConfirm(false)}
        size="mini"
        header="Confirm Salary Update"
        content="This action will add update staff salary. Continue ?"
      />

      <Grid padded>
        <Grid.Column computer={4} mobile={16} verticalAlign="middle">
          <Segment raised>
            {active === true ? (
              <Header
                subheader={profile.employee_category}
                image={profile.profile_image}
                content={profile.last_name + " " + profile.first_name}
              />
            ) : (
              <Header
                content="Finance Profile Update"
                subheader="Select Profile to Update"
              />
            )}
            <Form>
              <Dropdown
                placeholder="Select Staff"
                onChange={(e, target) => {
                  setActive(true);
                  setProfile(target.value);
                  setData(target.value.staff_id);
                }}
                fluid
                selection
                options={fieldStaff()}
              />
              <br />
              <Form.Field
                control={Input}
                label="Gross Salary"
                required
                onChange={(e) => setGrossSalary(parseFloat(e.target.value))}
                value={gross_salary}
              />
              <Form.Group widths="2">
                <Form.Field
                  control={Input}
                  label="Bonus"
                  onChange={(e) => setBonus(parseFloat(e.target.value))}
                  value={bonus}
                />

                <Form.Field
                  control={Input}
                  label="Deduction"
                  onChange={(e) => setDeduction(parseFloat(e.target.value))}
                  value={deduction}
                />
              </Form.Group>
              <Button
                disabled={!active}
                type="submit"
                onClick={() => setConfirm(true)}
                size="mini"
                color="orange"
              >
                Update Details
              </Button>
            </Form>
          </Segment>
        </Grid.Column>
        <Grid.Column computer={9} mobile={16}>
          <Table>
            <Table.Header>
              <Table.Row textAlign="center">
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Gross Salary</Table.HeaderCell>
                <Table.HeaderCell>Bonus</Table.HeaderCell>
                <Table.HeaderCell>Deductions</Table.HeaderCell>
                <Table.HeaderCell>Date Updated</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {_.sortBy(filteredStaff, (sd) => sd.last_name).map((st, idx) => (
                <Table.Row textAlign="center" key={idx}>
                  <Table.Cell>{st.last_name + " " + st.first_name}</Table.Cell>
                  <Table.Cell>{st.gross_salary}</Table.Cell>
                  <Table.Cell>{st.bonus}</Table.Cell>
                  <Table.Cell>{st.deduction}</Table.Cell>
                  <Table.Cell>{st.salary_change_date}</Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Grid.Column>
        <Grid.Column computer={3} mobile={16}>
          <Header content="Profile Filters" subheader="Status" />
          <List relaxed>
            {fieldStaff().map((stf, idx) => (
              <List.Item key={idx}>
                <Image avatar src={stf.image} />
                <List.Content>
                  <List.Header as="a" content={stf.text} />
                </List.Content>
                {stf.value.payroll_status === undefined ||
                stf.value.payroll_status === "Active" ? (
                  <Button
                    floated="right"
                    basic
                    positive
                    compact
                    onClick={() => changeStatus(stf.value._id, "In Active")}
                    size="mini"
                    circular
                    icon="checkmark"
                  />
                ) : (
                  <Button
                    floated="right"
                    basic
                    onClick={() => changeStatus(stf.value._id, "Active")}
                    negative
                    compact
                    size="mini"
                    circular
                    icon="cancel"
                  />
                )}
              </List.Item>
            ))}
          </List>
        </Grid.Column>
      </Grid>
    </Segment>
  );
}
